.mySwiper{
  box-sizing: border-box;
  overflow: hidden;
}
  
  .card {
    width: 65vw;
/* height: 50vw; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
                rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
    background-color: white;
    border-radius: 0.5em;
    padding: 3vw;
 
 

  }
.compititive-head{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.compititive-head>h1{
  font-size: 2.8vw;
  margin: 3vw 0vw 0vw 0vw;
}

  
.card-title{
  font-size: 3vw;
  margin: 0px 0px 1vw 0px;

}
  
  .card-text{
    font-size: 1.7vw;
    line-height: 1.8vw;
    font-weight: 400;
  }



  .swiper {
    width: 75%;
    height: 20vw;
    margin-bottom: 2.2vw;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper-button-prev, .swiper-button-next{
    color: black;
    font-size: 0.5vw;
  }
.swiper-pagination-bullet{
    background-color: black;
  }

    /* responsive 320px to 380px */
  
    /* @media screen and (min-device-width: 320px) and (max-device-width: 380px){

      .swiper {
        height: 35vw;
        width: 100%;
     
      }
      .card {
        width: 90vw;
      }
      .card-text{
        font-size: 3.5vw;
        line-height: 3vw;
      }
      .swiper-pagination-bullet{
    
        display: none !important;
        background-color: black;
      }
      .card-title{
        font-size: 5vw !important;
      }
      .compititive-head>h1{
        font-size: 6.5vw;
      }
  
    } */
  
  /* responsive 380px to 480px */
  
  @media screen and (min-device-width: 320px) and (max-device-width: 480px){
    .swiper {
      margin-top: 5vw;
      height: 40vw;
      width: 100%;
   
    }
    .card {
      width: 90vw;
    }
    .card-text{
      font-size: 3.5vw;
      line-height: 4vw;
    }
    .swiper-pagination-bullet{
      /* margin-top: 15vw; */
      display: none !important;
      background-color: black;
    }
    .card-title{
      font-size: 5vw !important;
    }
    .compititive-head>h1{
      font-size: 6.5vw;
    }

  }
