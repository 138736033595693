.success-message{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #595959;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  transition: ease-in .09s;
}
.success-message>p{
  line-height: 25px;
  padding: 20px;
}
.Form_Section{
  width: 48%;
 display: flex;
padding: 50px 100px;
align-items: center;
justify-content: center;

}
.form-section-22{
width: 100%;
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: space-between;

}
.register-form{
display: flex;
/* align-items: center; */
justify-content: center;
flex-direction: column;
}
.form-heading-33>h2{
padding: 5px 0px;
font-size: 35px;

}
.Lets_talkSection{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Lets_TalkText>h2{
font-size: 22px;

}
.ConnectWith_Us{
  width: 100%;


}
 input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea,
select {
  background: rgba(255,255,255,0.1);
  border: none;
  font-size: 16px;
  height: auto;
  margin: 0;
  outline: 0;
  padding: 15px;
  width: 100%;
  background-color: #e8eeef;
  color: #000000;
  box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  margin-bottom: 30px;
  border-radius: 10px;
}
.btnFormSubmit {
padding: 15px 40px;
border:1px solid rgb(173, 173, 169);
font-size: 16px;
text-transform: uppercase;
color: #000;
background-color: #fff;

border-radius: 10px;

cursor: pointer;
outline: none;
}

.btnFormSubmit:hover{
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;

}

.PhoneCallSection>p{  
  font-size: 20px;
  line-height: 40px;
  font-weight: 600;
 
}
.PhoneCallSection>p>a{
text-decoration: none;
color:#102d70;
font-weight: 500;
}
.EmailSection>a{
  font-size: 24px;
  text-decoration: none;
  color: black;
  font-size: 18px;

}
.EmailSection>h2{
  font-size: 20px;
  font-weight: 300;
  padding:  8px 0px;

}
.EmailSection>a{
font-size: 22px;
color:#102d70;
}
.inputFields-22{
width: 100%;
}
.form-heading-33{
width: 100%;
padding: 15px 0px;

}

  /* @media screen and (min-width: 380px )and (max-width:480px){
.ContactUs_Header-text{
  padding: 10px;
}
.ContactUs_Header{
  height: auto;
}
.ContactUs_Header-text > h1{
  font-size: 14px;
}
.ContactUs_Header-text > p{
  font-size: 9px;
}
.Form_Section{
  padding: 5px;
  width: 100%;
}
.form-heading-33{
  padding: 5px;
}
.form-heading-33 > h2{
  padding: 2px 0px;
  font-size: 16px;
}
.form-heading-33>p{
  font-size: 10px;
}


input[type="text"], input[type="password"], 
input[type="date"], input[type="datetime"], 
input[type="email"], input[type="number"], 
input[type="search"], input[type="tel"], 
input[type="time"],
 input[type="url"], textarea, select{
  padding: 10px;
  font-size: 10px;
  margin-bottom: 14px;
  border-radius: 5px;
 }
 .inputFields-22{
  padding: 8px 20px;
 }
 .btnFormSubmit{
  font-size: 12px;
  border-radius: 8px;
  padding: 10px 20px;
 }
 .Lets_talkSection{
  background-color:#e8eeef;
  padding: 10px 0px;
  width: 100%;
 }
 .Lets_TalkText > h2,.EmailSection > h2,.EmailSection > a{
  font-size: 14px;
 }
 .PhoneCallSection > p{
  font-size: 12px;
  line-height: 19px;
 }
 .EmailSection > h2{
  padding: 0px;
 }
} */



@media screen and (min-width: 320px )and (max-width:480px){
 
.ContactUs_Header-text{
  padding: 10px;
}
.ContactUs_Header{
  height: 60vw;
  padding-left: 4vw;

        background-position-x: center;
        background-size: cover;
        background-repeat: no-repeat;
}
.ContactUs_Header-text > h1{
  font-size: 7vw;
  margin-bottom: 3vw;
}
.ContactUs_Header-text > p{
  font-size: 4.5vw;
  line-height: 4.8vw;
}
.ContactUs_Header-text{
  width: 75%;
}
.Form_Section{
  padding: 2vw;
  width: 100%;
}
.form-heading-33{
  padding: 2vw;
}
.form-heading-33 > h2{
margin: 0px;
  font-size: 5vw;
}
.form-heading-33>p{
  font-size: 4vw;
  line-height: 4.5vw;
}


input[type="text"], input[type="password"], 
input[type="date"], input[type="datetime"], 
input[type="email"], input[type="number"], 
input[type="search"], input[type="tel"], 
input[type="time"],
 input[type="url"], textarea, select{
  padding: 3.5vw;
  font-size: 3.5vw;
  margin-bottom: 5vw;
  border-radius: 2vw;
 }
 .inputFields-22{
  padding: 3vw 5vw;
 }
 .btnFormSubmit{
  font-size: 4vw;
  border-radius: 2vw;
  padding: 3vw 6vw;
 }
 .Lets_talkSection{
  background-color:#e8eeef;
  padding: 10px 0px;
  width: 100%;
 }
 .Lets_TalkText > h2,.EmailSection > h2,.EmailSection > a{
  font-size: 6vw;
 }
 .PhoneCallSection > p{
  font-size: 5vw;
  line-height: 7vw;
 }
 .EmailSection > h2{
  padding: 0px;
 }

 .success-message{
  padding: 0px;
  width:250px

}
.success-message>p{
  line-height: 15px;
  padding: 25px;
  font-size: 12px;
}


}
@media screen and (min-width: 380px )and (max-width:480px){
  .success-message{
    padding: 0px;
    width:250px
  
  }
  .success-message>p{
    line-height: 15px;
    padding: 25px;
    font-size: 14px;
  }

}