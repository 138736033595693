.manufacturing-section{
    background-image: url(../../../assets/Medical\ BG.jpg);
    width: 100%;
    height: 33vw;
    background-size: 100%;
    display: flex;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    padding: 0px 6vw;

  }
  
  .manufacturing-header{
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
  }
  .manufacturing-header >h1{
    font-size: 45px;
    text-align: center;
    color: white;
  }
  .manufacturing-header >p{
    line-height: 2.2vw;
      font-size: 1.6vw;
      font-weight: 600;
      color: #d0d0d0;
      text-align: center;
      width: 80vw;
  
  }
  
  .manufacturing-title-around-span:before {
    width: 8vw;
    margin-right: 15px;
  }
  
  .manufacturing-title-around-span:after {
    flex-grow: 1;
    margin-left: 15px;
  }
 
  .manufacturing-title-around-span{
    display: flex;
    align-items: center;
    color: rgb(0, 174, 207);
    position: relative;
    text-transform: uppercase;
    font-size: 45px;
    margin: 40px 0px 0px 0px;
  }
  
  .manufacturing-title-around-span:before,
  .manufacturing-title-around-span:after {
    content: "";
    height: 1em;
    background-color:rgb(0, 174, 207);
    opacity: 10%;
  }
 
  .health-fitness-title-around-span span {
    display: inline-block;
    line-height: 1em;
    flex-shrink: 0;
  }
 
 
 
  
  /* ------right section------- */
  
  .manufacturing-title-around-span-text {
    display: flex;
    align-items: center;
    color:rgb(0, 174, 207);
    position: relative;
    font-size: 45px;
    text-transform: uppercase;
    margin: 40px 0px 20px 0px;
  }
  
  .manufacturing-title-around-span-text:before,
  .manufacturing-title-around-span-text:after {
    content: "";
    height: 1em;
      background-color:rgb(0, 174, 207);
    opacity: 10%;
  }
  
  .manufacturing-title-around-span-text:before {
    flex-grow: 1;
    margin-right: 15px;
  }
  
  .manufacturing-title-around-span-text:after {
    width: 8vw;
    margin-left: 15px;
  }
  
  .manufacturing-title-around-span-text span {
    display: inline-block;
    line-height: 1em;
    flex-shrink: 0;
  }
  
  /* ------------------ */
 
  .manufacturing-heading-intro{
    width: 100%;
    display: flex;
    padding: 0px 9vw;
  }
  
  .manufacturing-box {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .manufacturing-sub-heading{
    text-transform: uppercase;
    font-size: 28px;
    text-align: left;
    color: #8e8383;
 
  }
  .manufacturing-paragraph-left-sn {
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 500;
    text-align: left;
 
  }
  .manufacturing-sub-heading-right{
    text-transform: uppercase;
    font-size: 28px;
    text-align: right;
    color: #8e8383;
 
  }
  .manufacturing-paragraph-right-sn {
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 500;
    text-align: right;
 
  }
 
  .manufacturing-images-section{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
  .manufacturing-images-section > img {
    width: 16vw;
  }
 
 
  @media screen and (min-device-width: 320px) and (max-device-width: 480px){
    .manufacturing-section{
      height: 65vw;
      background-size: cover;
    }
    .manufacturing-header >h1{
      font-size: 5vw;
    }
    .manufacturing-header >p{
      font-size: 3.5vw;
      line-height: 3.5vw;
    }
  }