

/* .section {
  height: 50vh;
} */
.section {
    height: 5vh;
    /* background-color: #f6f6f6; */
  }

.icon {
  fill: none;
  stroke: currentColor;
  stroke-width: 2;
}
.about-us-page{
    box-sizing: border-box;
    background-color:#f6f6f6;
}
.container111{
  /* margin-top: -11vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container111 >img{
  width: 100%;
  height: auto;
  display: block;
}
.about-us-inner-text{
  position: absolute;
  color: white;
  text-align: left;
  width: 70vw;
  padding: 20px;
  border-radius: 8px;
}

.header-about {
  display: flex;
  align-items: center;
  /* padding-top: 9.375em;
  color: #436BD7;
  background-color: currentColor;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), transparent 300px); */
}
@media (max-width: 43.75em) {
  .header {
    text-align: center;
  }
}
.header .container {
  max-width: 100%;
}
.header__top {
  display: flex;
  align-items: center;
}
@media (max-width: 43.75em) {
  .header__top {
    flex-direction: column-reverse;
  }
}
.header__top .icon {
  width: 1.25em;
  height: 1.25em;
  margin-right: 0.625em;
}
.header__logo {
  width: 200px;
  margin-right: 2em;
  border-radius: 5px;
}
@media (max-width: 43.75em) {
  .header__logo {
    margin: 1.25em 0 0;
  }
}
.header__title__small, .header__link, .header__blurb {
  color: rgba(255, 255, 255, 0.7);
}
.header__title {
  margin: 0 0 0.3611111111em;
  font-weight: normal;
  font-size: 2.25rem;
}
.header__title__small {
  display: block;
  font-size: 1.5rem;
}
.header__title__strong {
  font-weight: bold;
  color: white;
}
.header__link {
  display: inline-flex;
  align-items: center;
  transition: 0.25s;
}
.header__link:hover, .header__link:focus {
  color: white;
  text-decoration: none;
}
.header__link:active {
  color: rgba(255, 255, 255, 0.4);
  transition: 0s;
}
.header__blurb {
  /* margin: 2.5em 0; */
  /* margin: 4vw 0vw; */
  color: #ffffff;
  line-height: 1.2;
  /* width: 80%; */
  margin-top: 12vw;
    font-size: 1.9vw;
    font-weight: 600;
}
.header__tiles {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:1vw;
  flex-wrap: wrap;
  width: 90%;
  height: 150px;
  border-radius: 2vw;
  margin-bottom: -62px;
  background: #ffffff;
  box-shadow: inset 0 0 0 1px white, 0 10px 30px rgba(0, 0, 0, 0.15);
}
.header__tiles>h2{
  margin: 0px;
  font-size: 2.1vw;
}
.header__tiles>p{
  width: 80%;
  font-size: 1.5vw;
  text-align: center;
}
.header__tile {
  flex: 1;
  height: 100%;
  position: relative;
  padding: 2em 1em;
  font-size: 1.25em;
  white-space: nowrap;
  color: #555;
  border: 1px solid transparent;
}
@media (max-width: 62em) {
  .header__tile {
    flex-basis: 50%;
  }
}
@media (max-width: 30em) {
  .header__tile {
    flex-basis: 100%;
  }
}
.header__tile::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  transition: 0.3s;
}
.header__tile > span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}
.header__tile:hover, .header__tile:focus {
  color: inherit;
  text-decoration: none;
  outline: none;
}
.header__tile:hover > span, .header__tile:focus > span {
  z-index: 1;
}
.header__tile:hover .icon, .header__tile:focus .icon {
  color: currentColor;
}
@media (min-width: 49.4375em) {
  .header__tile:hover::before, .header__tile:focus::before {
    z-index: 1;
    transform: scale(1.15);
    box-shadow: 0 20px 45px rgba(0, 0, 0, 0.2);
  }
  .header__tile:active::before {
    transition: 0.1s;
    transform: scale(1.2);
  }
}
.header__tile .icon {
  width: 42px;
  height: 42px;
  margin-right: 0.75em;
  color: #ccc;
  transition: 0.3s;
}