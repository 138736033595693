.row {
  padding: 1.5vw 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5vw;
}

.separator {
  margin-bottom: 30px;
  width: 5vw;
  height: 3px;
  background: #777;
  border: none;
}

.title {
  text-align: center;
}
.title .row {
  padding: 50px 0 0;
}
.title h1 {
  text-transform: uppercase;
  font-size: 3vw;
}
.title .separator {
  margin: 0 auto 50px;
}

.item {
  width: 48vw;
  height: 19vw;
  background-color: #f7f7f7;
  border-bottom: 0.3vw solid transparent;
  opacity: 0;
  transform: translateY(20px) scale(0.9);
  transition: border-bottom 0.4s ease-in-out;
  animation: fadeInUp 0.6s ease-in-out forwards;
}
.item:hover {
  border-bottom: 0.3vw solid black; /* default color if nth-child doesn't apply */
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.item .item-in {
  padding: 40px;
  position: relative;
}

.item h4 {
  font-size: 2vw;
  margin: 0px 0px 1vw 0px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.item p {
  font-size: 1.3vw;
  font-weight: 400;
  line-height: 1.6vw;
}
.item a {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: #666666;
  margin-top: 10px;
}

/* Different border-bottom colors for each item */
.item:nth-child(1):hover {
  border-bottom-color: #F95933;
}
.item:nth-child(2):hover {
  border-bottom-color: #6441A0;
}
.item:nth-child(3):hover {
  border-bottom-color: #7DC85C;
}
.item:nth-child(4):hover {
  border-bottom-color: #00AECF;
}
.item:nth-child(5):hover {
  border-bottom-color: #000000;
}

.item.in-view {
  opacity: 1;
  transform: translateY(0) scale(1);
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
