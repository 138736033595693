/* Map.css */
.Map-video-container {
  /* margin: 2vw 0vw; */
    position: relative;
    width: 100%;

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  .map-title{
    width: 65%;
  }
  .map-title>img{
    margin-top: 10vw;
    width: 100%;
  }
  .video-map {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .industries-list-container {
   
    position: absolute;
    top: 17%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    z-index: 1;
    color: rgb(0, 0, 0);
  }
  .industries-list-container2 {
   width: 100%;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    color: rgb(0, 0, 0);
  }
  .industry-item2{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .industry-item2 >p{
    width: 90%;
    font-size: 1.5vw;
    text-align: center;
    font-weight: 600;
    line-height: 2.5vw;
  }
  
  .industry-item {
    background: rgba(255, 255, 255, 0.9);
    width: 10vw;
    height: 10vw;
    border-radius:1vw ;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  }
  .industry-item>img{
    width: 50%;
  }
  .industry-item >p{
    width: 90%;
    font-size: 1vw;
    text-align: center;
    font-weight: 600;
    line-height: 2.5vw;
  }
  