/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.indu-pages-section675555 {
  width: 100%;

}

.container1234 {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  list-style: none;
  display: flex;
  gap: 10px;
  padding: 0 50px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.list.dragging {
  scroll-behavior: auto;
  cursor: grab;
  user-select: none;
  pointer-events: none;
}

.list li {
  background-color: white;
  color: black;
  padding: 5px 20px;
  font-size: 21px;
  cursor: pointer;
}

.selected-list-item {
  border: 1px solid #f95932;
} */


.indu-pages-section675555 {
  width: 100%;
  position: fixed;
  top: 70px;
  background-color: white;
  z-index: 1000;
  border-top: 0.5px solid grey;
}

.container1234 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  width: 100%;
  padding: 10px 0;
  overflow-x: hidden;
}

.list {
  list-style: none;
  display: flex;
  gap: 10px;
  padding: 0;
  margin: 0;
  overflow-x: auto; /* Allow horizontal scrolling if needed */
  scrollbar-width: none; /* Hide scrollbar in supported browsers */
  -ms-overflow-style: none; /* Hide scrollbar in IE/Edge */
}

.list::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

.list li {
  background-color: white;
  color: black;
  padding: 10px 20px;
  font-size: 1.2vw;
  font-weight: 400;
  cursor: pointer;
  border: 1px solid transparent;
  transition: border-color 0.3s ease;
}

.list li.selected-list-item {
  /* border-color: #f95932; */
  border-bottom: 2px solid #f95932;
}

/* ----MEDIA QUARIES-------- */
@media screen and (min-device-width:1336px) and (max-device-width:1446px){
  .list li {
    padding: 10px 20px;
    font-size: 16px;
  }
  
  }
  @media screen and (min-device-width:1246px) and (max-device-width:1336px){
    .list li {
      padding: 5px 15px; 
      font-size: 16px;
    }
    
    }
    
    @media screen and (min-device-width:1024px) and (max-device-width:1246px){
      .list li {
        padding: 5px 11px; 
        font-size: 13px;
      }
      
      }
      
@media screen and (min-device-width:920px) and (max-device-width:1024px){
  .list li {
    padding: 5px 8px; 
    font-size: 15px;
  }
  
  }
  

