.Contact_UsSection{
    width: 100%;
    background-color: white;
   
}
.ContactUs_Header{
    background-image: url(../../assets/ContactUs.webp);
    background-size: cover;
    height: 300px;
display: flex;
align-items: center;
justify-content: flex-start;
    color: white;
}
.ContactUs_Header-text{
    width: 60%;
    padding: 40px;
}
.ContactUs_Header-text>h1{
    font-size: 45px;


   
}
.ContactUs_Header-text>p{
    font-size: 20px;
   
}


@media (min-width: 320px) and (max-width: 379px) {
    
}