.Banking-img-container {
  background-image: url(../../../assets/EquitasIMGb.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 33vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 6vw;

  text-align: center;
}
 
.Banking-title-sec {
  text-align: center;
}
.Banking-title-sec > h1 {
  font-size: 3vw;
  color: white;
}
.Banking-heading-intro-section > p {
  line-height: 2.2vw;
  font-size: 1.6vw;
  width: 80vw;
  font-weight: 600;
  color: #d0d0d0;
  text-align: center;
}




.industries__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  background: radial-gradient(circle, rgba(187, 233, 255, 0.1), rgba(177, 175, 255, 0.1), rgba(255, 166, 47, 0.1), rgba(3, 174, 210, 0.1));
  background-size: 200% 200%;
  animation: gradientAnimation 5s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.industries__list {

  width: 100%;
  display: flex;
  flex-direction: column;
}

.industry__item {
  position: relative;
  width: 100%;
min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;



}

.industry__item__title {
  padding: 0vw 8vw;
  width: 60%;
  text-align: left;
  transform: translateX(0);

}
.industry__item__title>h1{

  font-size: 2.8vw;
  margin: 0px;
  margin-bottom: 0.5vw;
}
.industry__item__title>h2{

  font-size: 1.7vw;
  line-height: 1.1;
  margin: 0px;
  /* margin-bottom: 1vw; */
}
.industry__item__title>p{
  font-size: 1.5vw;
  line-height: 1.3;
}

.industry__item img {
  width: 25vw;
  height: auto;
  object-fit: cover;
  border-radius: 100vw;
}

@media (min-width: 1900px) {


  .industry__item img {
    width: auto;
    height: 80%;
  }
}



@media screen and (min-device-width: 320px) and (max-device-width: 480px){

  .Banking-img-container{
    height: 65vw;
  }
  .Banking-title-sec > h1{
    font-size: 5vw;
  }
  .Banking-heading-intro-section > p{
    font-size: 3.5vw;
    /* font-weight: 400; */
    line-height: 3vw;
  }


  .industry__item__title{
    width: 100%;
  }

  .industry__item img{
    display: none;
  }

  .industry__item{
    min-height: 20vh;
  margin: 10vw 0vw;
  }
  .industry__item__title>h1{
    font-size: 5vw;
  }
  .industry__item__title{
    padding: 0vw 0vw 0vw 5vw;
  }
  .industry__item__title>h2{
    font-size: 4vw;

  }
  .industry__item__title>p{
    font-size: 3.5vw;
  }
}