.BackEndTech-Bg{
width: 100%;
/* height: 500px; */
background-color: seashell;
display: flex;
align-items: center;
justify-content: center; 
 background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;  


}



.BackEndTech-Bg{
    background-image:url(../../../assets/python-bg.png);
}
.BET-Tech-left{
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

}
.BET-Tech-left>p{
  font-size: 18px;
  padding: 3px;
  color: hsla(0,0%,100%,.6);
}
.BET-Tech-left>img{
  width: 400px;
}
.BET-Tech-right{
  width: 50%;
  padding: 20px;
  text-align: justify;

}
.BET-Tech-right>p{
font-size: 20px;
padding: 3px;
color: hsla(0,0%,100%,.6);
}

/* --------------Responsive----------- */

@media screen and (min-device-width:1024px)and (max-device-width:1280px){
  .BackEndTech-Bg{
  padding: 25px 18px;

  }
  .BET-Tech-right>p{
    font-size: 17px;

  }
  .BET-Tech-left>img{
    width: 380px;
  }

}

@media screen and (min-device-width:920px)and (max-device-width:1024px){
  .BackEndTech-Bg{
  padding: 20px 18px;

  }
  .BET-Tech-right>p{
    font-size: 16px;
    padding: 2px;

  }
  .BET-Tech-left>img{
    width: 350px;
  }

}

@media screen and (min-device-width:768px)and (max-device-width:920px){
  .BackEndTech-Bg{
    display: flex;
    flex-direction: column;
  padding: 20px 18px;


  }
  .BET-Tech-right{
    width: 100%;
    padding: 10px 18px;
  }
  .BET-Tech-right>p{
    font-size: 15px;
    padding: 2px;

  }
  .BET-Tech-left>img{
    width: 320px;
  }

}

@media screen and (min-device-width:585px)and (max-device-width:768px){
  .BackEndTech-Bg{
    display: flex;
    flex-direction: column;
  padding: 15px;


  }
  .BET-Tech-right{
    width: 100%;
    padding: 10px 15px;
  }
  .BET-Tech-right>p{
    font-size:  14px;
    padding: 2px;

  }
  .BET-Tech-left>img{
    width: 320px;
  }

}
@media screen and (min-device-width:480px)and (max-device-width:585px){
  .BackEndTech-Bg{
    display: flex;
    flex-direction: column;
  padding: 13px;


  }
  .BET-Tech-right{
    width: 100%;
    padding: 10px;
  }
  .BET-Tech-right>p{
    font-size:  12px;
    padding: 2px;

  }
  .BET-Tech-left>img{
    width: 250px;
  }

}

@media screen and (min-device-width: 380px) and (max-device-width: 480px){

  .BackEndTech-Bg{
    display: flex;
    flex-direction: column;
    padding: 10px;
    
   }
   
    .BET-Tech-right{
        width: 100%;
        
    
    }
    .BET-Tech-right>p{
      font-size: 11px;
      padding: 1px;
   
    }
    .BET-Tech-left>img{
      width: 200px;
    }


}

@media screen and (min-device-width: 320px) and (max-device-width: 380px){

  .BackEndTech-Bg{
    display: flex;
    flex-direction: column;
    padding: 10px;
    
   }
   
    .BET-Tech-right{
        width: 100%;
        
    
    }
    .BET-Tech-right>p{
      font-size: 10px;
      padding: 1px;
   
    }
    .BET-Tech-left>img{
      width: 180px;
    }


}




/* ---------------- node js-------------------- */


.BackEndTech-Bg-nodejs{
  width: 100%;
/* height: 500px; */
background-color: seashell;
display: flex;
align-items: center;
justify-content: center; 
 background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;  
  padding: 30px 20px;

}

.BackEndTech-Bg-nodejs{
    background-image:url(../../../assets/nodejs-bg.png);
}
.BET-Tech-right-nodejs{
  width: 50%;
  padding: 20px;
  text-align: justify;

}
.BET-Tech-right-nodejs>p{
font-size: 18px;
padding: 3px;
color: hsla(0,0%,100%,.6);
}



/* --------------Responsive--------------- */

@media screen and (min-device-width:1024px) and (max-device-width:1280px){
  .BackEndTech-Bg-nodejs{
    padding: 25px 18px;

 }
 .BET-Tech-right-nodejs>p{
  font-size: 17px;
 }

} 

@media screen and (min-device-width:920px) and (max-device-width:1024px){
  .BackEndTech-Bg-nodejs{
    padding: 25px 18px;

 }
 .BET-Tech-right-nodejs>p{
  font-size: 16px;
  padding: 2px;
 }

} 
@media screen and (min-device-width:768px) and (max-device-width:920px){
  .BackEndTech-Bg-nodejs{
    display: flex;
    flex-direction: column-reverse;
    padding: 25px 18px;

 }
 .BET-Tech-right-nodejs>p{
  font-size: 15px;
  padding: 2px;
 }
 .BET-Tech-right-nodejs{
  width: 100%;
  padding: 10px 18px;
 }

} 

@media screen and (min-device-width:585px) and (max-device-width:768px){
  .BackEndTech-Bg-nodejs{
    display: flex;
    flex-direction: column-reverse;
    padding: 25px 18px;

 }
 .BET-Tech-right-nodejs>p{
  font-size: 12px;
  padding: 2px;
 }
 .BET-Tech-right-nodejs{
  width: 100%;
  padding: 10px 18px;
 }

} 


@media screen and (min-device-width:480px)and (max-device-width:585px){

  .BackEndTech-Bg-nodejs{
    display: flex;
    flex-direction: column-reverse;
    padding: 15;

 }
 .BET-Tech-right-nodejs>p{
  font-size: 12px;
  padding: 2px;
 }
 .BET-Tech-right-nodejs{
  width: 100%;
  padding: 10px;
 }



}

@media screen and (min-device-width:380px)and (max-device-width:480px){
  .BackEndTech-Bg-nodejs{
    display: flex;
    flex-direction: column-reverse;
    padding: 10px;
  
  }
  
  .BET-Tech-right-nodejs{
    width: 100%;
   
  
  }
  .BET-Tech-right-nodejs>p{
  font-size:11px;
  padding: 1px;
  
  }

}

@media screen and (min-device-width:320px) and (max-device-width:380px){
  
.BackEndTech-Bg-nodejs{
  display: flex;
  flex-direction: column-reverse;
  padding: 10px;

}

.BET-Tech-right-nodejs{
  width: 100%;
 

}
.BET-Tech-right-nodejs>p{
font-size:10px;
padding: 1px;

}



}


