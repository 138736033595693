  /* .header {
    position: fixed;
    height: 70px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: .3s ease-in;
    overflow: hidden;
    padding: 0px 20px;
    box-sizing: border-box;

    background: transparent;
  
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }

  .header-bg {
    box-sizing: border-box;
    background-color: white;
    z-index: 9999;
  }

  .header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    height: 100%;
  }


  .nav-link-su {
    font-family: 'Open Sans', Arial, sans-serif;
    font-weight: 500;
    font-style: normal;
    color: white;
    font-size: 1.25vw;
  }
  .nav-link-su.active {
    font-family: 'Open Sans', Arial, sans-serif;
    font-weight: 500;
    font-style: normal;
    color: black;
    font-size: 1.25vw;
  }


  

  .supernal-logo {
    margin-left: 2vw;
    width: 180px;
    height: auto;
  }
  .supernal-logo-active{
    mix-blend-mode: exclusion;
  }

  .header .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(100vw - 98vw);
  }

  .header .nav-item {
    padding: 0.6rem;
    font-weight: 500;
  }

  .header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
  }

  .hamburger {
    display: none;
  }

  .CaButton {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 5px;
    font-size: 17px;
    font-weight: 600;
    padding: 1em 2em;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid rgb(74, 74, 74);
    box-shadow: 0 0 0 0 rgb(35, 35, 35);
  }

  .CaButton:hover {
    transform: translateY(-4px) translateX(-2px);
    box-shadow: 2px 5px 0 0 rgb(0, 0, 0);
  }

  .CaButton:active {
    transform: translateY(2px) translateX(1px);
    box-shadow: 0 0 0 0 black;
  }

  .active3 {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px;
    border-radius: 5px;
    position: relative;
    z-index: 0;
    font-weight: 600;
    cursor: pointer;
  }

  .active3:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing_animation 20s linear 0.3;
    opacity: 1;
  }

  .active3:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    left: 0;
    top: 0;
    z-index: -1;
  }

  @keyframes glowing_animation {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
  }

  .header .nav-menu .active3 a {
    color:#f95932; 
  }

  .glow-on-hover {
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    font-weight: 600;
  }

  .glow-on-hover:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing_54134 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }

  .glow-on-hover:active {
    color: #000;
  }

  .glow-on-hover:active:after {
    background: transparent;
  }

  .glow-on-hover:hover:before {
    opacity: 1;
  }

  .glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    left: 0;
    top: 0;
  }

  @keyframes glowing_54134 {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
  }

  @media (max-width: 720px) {
    .header {
        display: none !important;
    }
    .hide-on-mobile {
        display: none;
    }
  }

  @media (min-width: 1280px) and (max-width: 1340px) {
    .header .navbar {
        padding: 0px;
    }
    .CaButton {
        padding: 0.7em 1em;
    }
  }

  @media (min-width: 1340px) and (max-width: 1440px) {
    .CaButton {
        padding: 0.7em 1em;
    }
  }

  @media screen and (max-width: 940px) {
    .header {
        max-width: 100%;
        background-color: rgba(0, 0, 0, .9);
    }
    .header .navbar {
        max-width: 100%;
    }
    .hamburger {
        display: block;
    }
    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: rgba(0, 0, 0, .9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }
    .nav-item {
        margin: 1.5rem 0;
    }
    .header .navbar img {
        width: 150px;
    }
  } */


   
.no-scroll {
  overflow: hidden;
}
 
.navbar {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 1vw 3vw;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  transition: background-color 0.3s ease, color 0.3s ease;
}
 
.supernal-logo {
  margin-left: 2vw;
  width: 180px;
  height: auto;
}
.supernal-logo-active{
  mix-blend-mode: exclusion;
}
 
.navbar.scrolled {
  background-color: white;
  color: black;
}
 
.navbar-logo {
  color: #fff;
  font-size: 1.5rem;
}
 
.navbar.scrolled .navbar-logo {
  color: black;
}
 
.hamburger {
  display: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
}
 
.navbar.scrolled .hamburger {
  color: black;
}
 
.navbar-links {
  display: flex;
  gap: 1vw;
  align-items: center;
}
 
.navbar-item {
  position: relative;
  color: #ffffff;
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 1.5vw;
}
 
.color-change-link {
  color: black !important;
}
 
.sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  padding: 0.5rem 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
 
.sub-menu a {
  color: #333;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  white-space: nowrap;
}
 
.sub-menu a:hover {
  background-color: #f0f0f0;
}
 
.sub-menu.open {
  display: block;
}
 
.close-btn {
  display: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}
 
.CaButton {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  padding: 1em 2em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgb(74, 74, 74);
  box-shadow: 0 0 0 0 rgb(35, 35, 35);
}
 
.CaButton:hover {
  transform: translateY(-4px) translateX(-2px);
  box-shadow: 2px 5px 0 0 rgb(0, 0, 0);
}
 
.CaButton:active {
  transform: translateY(2px) translateX(1px);
  box-shadow: 0 0 0 0 black;
}
 
/* Responsive Styles */
@media (max-width: 768px) {
  .navbar-links {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 250px;
    background-color: #000000;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 3rem;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
  }
 
  .navbar-links.open {
    transform: translateX(0);
  }
 
  .navbar-item {
    padding: 1rem;
    width: 100%;
    text-align: left;
    border-top: 1px solid #444;
    font-size: 3vw;
    color: white !important; /* Ensure nav items are always white on mobile */
  }
 
  .hamburger {
    display: block;
  }
 
  .overlay-burger {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 999;
  }
 
  .overlay-burger.open {
    display: block;
  }
 
  .close-btn {
    display: block;
  }
 
  .sub-menu {
    position: static;
    width: 100%;
    padding: 0;
    border: none;
    box-shadow: none;
  }
 
  .sub-menu a {
    color: black;
    padding: 1rem;
    border-top: 1px solid #444;
  }
 
  .sub-menu a:hover {
    background-color: #555;
  }
 
  .sub-menu.open {
    display: block;
  }
}
 
@media (min-width: 769px) {
  .sub-menu {
    display: none !important;
  }
}
 
@media (min-width: 320px) and (max-width: 480px) {

  .navbar-item {
    padding: 1rem;
    width: 100%;
    text-align: left;
    border-top: 1px solid #444;
    font-size: 5vw;
    color: white !important; /* Ensure nav items are always white on mobile */
  }
  .supernal-logo{
    width: 40vw;
  }
 
}
 
 
 