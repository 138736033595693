/* src/components/ConnectWithUs.css */
.connect-with-us {
    /* padding: 2vw 0vw; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background-color:#ffffff;
  }
  
  .image-container-cc {
    position: relative;
    width: 100%;
 
  }
  
  .image-container-cc img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .text-overlay-cc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-88%, -50%);
    color: white;
    text-align: left;
    width: 48vw;
    padding: 20px;
    border-radius: 8px;
  }
  
  .text-overlay-cc h2 {
    line-height: 4vw;
    margin: 0;
    font-size: 3.6vw;
  }
  
  .text-overlay-cc p {
    margin: 3vw 0;
    font-size: 1.5vw;
    font-weight: 600;
    line-height: 1.8vw;
  }
  
  .text-overlay-cc button {
    padding: 0.8vw 1.5vw;
    font-size: 1em;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 0.8vw;
    cursor: pointer;
    font-size: 1.5vw;
    font-weight: 600;
    line-height: 1.8vw;
  }
  
  .text-overlay-cc button:hover {
    background-color: #000000;
    color: white;
    outline: 1px solid white;
  }
  
  @media screen and (min-device-width: 320px) and (max-device-width: 480px){

    .connect-with-us{
      height: 30vh;
      box-sizing: border-box;
      overflow: hidden;
    }
    .image-container-cc img{
      width: auto;
    }
    .text-overlay-cc{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: left;
    width: 85vw;
    padding: 5vw;

    }
    .text-overlay-cc h2{
      font-size: 5.5vw;
      line-height: 6vw;
    }
    .text-overlay-cc p{
      font-size: 4vw;
      line-height: 4.3vw;
      font-weight: 400;
    }
    .text-overlay-cc button{
      font-size: 4vw;
      padding: 2vw 4vw;
    }
  }