 .frontEnd-tech{
  width: 100%;
  /* height: 500px; */
  display: flex;
  align-items: center;
  justify-content: center; 
   background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative; 
    padding: 30px 20px; 
  
}
.frontEnd-tech{
  background-image:url(../../../assets/ReactNative-bg.png);
}
.FET-image-React{
    width: 50%;
    text-align: center;
    letter-spacing: 4.5px;
  
}
.FET-Text-React{
    width: 50%;
    padding: 0px 20px;
    color: whitesmoke;
    
}
.FET-image-React>img{
    width:400px;
}
.FET-Text-React>p{
    text-align: justify;
    font-size: 20px;
    padding: 3px;
    color: hsla(0,0%,100%,.6);
    margin-right: 40px;
} 
/* ----------------------------------- */


@media screen and (min-device-width:1024px)and (max-device-width:1280px){
    .frontEnd-tech{
        padding: 25px 18px;
    }
    .FET-image-React>img{
        width:350px;
    }
    .FET-Text-React>p{
     
        font-size: 17px;
    }

}

@media screen and (min-device-width:920px)and (max-device-width:1024px){
    .frontEnd-tech{
        padding: 22px 18px;
    }
    .FET-image-React>img{
        width:300px;
    }
    .FET-Text-React>p{
     
        font-size: 16px;
        padding: 2px;
    }

}

@media screen and (min-device-width:768px)and (max-device-width:920px){
    .frontEnd-tech{
        display: flex;
        flex-direction: column;
        padding: 20px 15px;
    }
    .FET-Text-React{
        width: 100%;
        padding: 10px 15px;
    }
    .FET-image-React>img{
        width:280px;
    }
    .FET-Text-React>p{
     
        font-size: 15px;
        padding: 2px;
    }

}

@media screen and (min-device-width:585px)and (max-device-width:768px){
    .frontEnd-tech{
        display: flex;
        flex-direction: column;
        padding:15px;
    }
    .FET-Text-React{
        width: 100%;
        padding: 10px 12px;
    }
    .FET-image-React>img{
        width:250px;
    }
    .FET-Text-React>p{
     
        font-size: 14px;
        padding: 2px;
    }

}

@media screen and (min-device-width:480px)and (max-device-width:585px){
    .frontEnd-tech{
        display: flex;
        flex-direction: column;
        padding:13px;
    }
    .FET-Text-React{
        width: 100%;
        padding: 10px;
    }
    .FET-image-React>img{
        width:220px;
    }
    .FET-Text-React>p{
     
        font-size: 13px;
        padding: 1px;
    }

}


@media screen and (min-device-width: 380px) and (max-device-width: 480px){
    .frontEnd-tech{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
    .FET-image-React>img {
        width: 200px;
    }
    .FET-Text-React{
        width: 100%;
    }

    .FET-Text-React>p{
        font-size: 11px;
        padding: 1px;
    }




}

@media screen and (min-device-width: 320px) and (max-device-width: 380px){
    .frontEnd-tech{
     
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
    .FET-image-React>img {
        width: 180px;
    }
    .FET-Text-React{
        width: 100%;
    }

    .FET-Text-React>p{
        font-size: 10px;
        padding: 1px;
    }

}





  /* -----------Angular---------- */
  .FET-angular-Bg{
    background-image:url(../../../assets/Flutter-bg.png);

}
.FET-angular-Bg{
    width: 100%;
    /* height: 500px; */
    display: flex;
    align-items: center;
    justify-content: center; 
     background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;  
    padding: 30px 20px; 

 

}
 .FET-AG-left{
        width: 50%;
        color: white;
        padding: 0px 20px;
        text-align: justify;
  }
     .FET-AG-left>p{
        padding: 3px;
        font-size: 20px;
        color: hsla(0,0%,100%,.6);
        margin-right: 40px;

     }
     .FET-AG-right{
        width: 50%;
        color: rgb(154, 0, 0);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.5rem;
        filter: drop-shadow(0px 0px 1px white);
     } 
     .FET-AG-right>img{
        width: 400px;
     }
   

 /* ------------- Responsive------------ */

 @media screen and (min-device-width:1024px) and (max-device-width:1280px){
    .FET-angular-Bg{
        padding: 25px 18px;

    }
    .FET-AG-left>p{
        font-size: 17px;
        padding: 2px;

    }
    .FET-AG-right>img{
        width: 350px;
     }

 }

 @media screen and (min-device-width:920px) and (max-device-width:1024px){
    .FET-angular-Bg{
        padding: 25px 18px;

    }
    .FET-AG-left>p{
        font-size: 16px;
        padding: 2px;

    }
    .FET-AG-right>img{
        width: 320px;
     }

 }

 
 @media screen and (min-device-width:768px) and (max-device-width:920px){
    .FET-angular-Bg{
        display: flex;
        flex-direction: column-reverse;
        padding: 20px 15px;

    }
    .FET-AG-left{
        width: 100%;
        padding: 10px 15px;
    }
    .FET-AG-left>p{
        font-size: 15px;
        padding: 2px;

    }

    .FET-AG-right>img{
        width: 320px;
     }

 }

 @media screen and (min-device-width:585px) and (max-device-width:768px){
    .FET-angular-Bg{
        display: flex;
        flex-direction: column-reverse;
        padding: 20px 15px;

    }
    .FET-AG-left{
        width: 100%;
        padding: 10px 15px;
    }
    .FET-AG-left>p{
        font-size: 14px;
        padding: 2px;

    }

    .FET-AG-right>img{
        width: 300px;
     }

 }


 @media screen and (min-device-width:480px) and (max-device-width:585px){
    .FET-angular-Bg{
        display: flex;
        flex-direction: column-reverse;
        padding:15px;

    }
    .FET-AG-left{
        width: 100%;
        padding: 10px;
    }
    .FET-AG-left>p{
        font-size: 13px;
        padding: 1px;

    }

    .FET-AG-right>img{
        width: 250px;
     }

 }

 @media screen and (min-device-width: 380px) and (max-device-width: 480px){

    .FET-angular-Bg{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        padding: 10px;
    }
    .FET-AG-right>img{
     width: 180px;
    }
    .FET-AG-left{
        width: 100%;
        
    }
    .FET-AG-left>p{
        font-size: 10px;
        padding: 1px;
    }



 }
  @media screen and (min-device-width: 320px) and (max-device-width: 380px){

        .FET-angular-Bg{
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            padding: 10px;
        }
        .FET-AG-right>img{
         width: 150px;
        }
        .FET-AG-left{
            width: 100%;
            
        }
        .FET-AG-left>p{
            font-size: 9px;
            padding: 1px;
        }
    


     }
