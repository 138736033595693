.crm-erp-integration{
    background-color: #090979;
    width: 100%;
    height: 25vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .crm-erp-integration > h1 {
    width: 60%;
    font-size: 3vw;
    font-weight: bolder;
    color: white;
    text-align: center;
  }
  .crm-erp-container {
    width: 90%;
  margin: 0 auto;
  }
  .crm-erp-intro-item-con {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vw;
  }
  .crm-erp-intro-item-con >p {
    font-size: 1.5vw;
    line-height: 1.8vw;
  }
  .crm-erp-customer-cen-section{
   
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8vw;
  }
  .crm-erp-customer-cen-section >h2 {
    font-size: 2vw;
    font-weight: bolder;
    text-align: center;
  }
  .integration-imperative-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .integration-imperative-container >h1{
    font-size: 2vw;
   
  }
  .integration-imperative-container >p {
    font-size: 1.3vw;
    line-height: 1.8vw;
  }
  /* ---------- */
  .ide-integration-goals-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1vw;
    padding: 2.5vw 0.8vw;
   
  }
  .inte-section-item{
    width: 25vw;
    box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
    height: 20vw;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.9vw 1.8vw;
  }
  .inte-section-item >h2 {
    font-size: 1.3vw;
    font-weight: bolder;
   
  }
  .inte-section-item >p{
    font-size: 1.2vw;
    line-height: 1.2;
    text-align: center;
   
  }
  .crm-erp-summary{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  padding:1vw;
  }
  .crm-erp-summary >p{
    font-size: 1.3vw;
    line-height: 1.2;
    text-align: center;
   
    
  }
   
  /* --------------- */
   
   
  .projcard-container {
    margin-left: auto;
    margin-right: auto;
    width: 60vw;
    margin-top: 1vw;
  }
   
  .projcard {
    position: relative;
    width: 100%;
    height: 20vw;
    margin-bottom: 3vw;
    border-radius: 1vw;
    background-color: #fff;
    border: 0.2vw solid #ddd;
 
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0.4vw 1.5vw -0.8vw rgba(0, 0, 0, .66);
    transition: box-shadow 0.2s ease, transform 0.2s ease;
  }
   
  .projcard:hover {
    box-shadow: 0 2vw 2vw -2.6vw rgba(0, 0, 0, .18);
    transform: translate(0px, -3px);
  }
   
  .projcard::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(-70deg, #424242, transparent 50%);
    opacity: 0.07;
  }
   
  .projcard:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #424242, transparent 50%);
  }
   
  .projcard-innerbox {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
   
  .projcard-img {
    position: absolute;
    height: 20vw;
    width: 30vw;
    top: 0;
    left: 0;
    transition: transform 0.2s ease;
  }
   
  .projcard:nth-child(2n) .projcard-img {
    left: initial;
    right: 0;
  }
   
  .projcard:hover .projcard-img {
    transform: scale(1.05) rotate(1deg);
  }
   
  .projcard:hover .projcard-bar {
    width: 7vw;
  }
   
  .projcard-textbox {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 7%;
    bottom: 7%;
    left: 32vw;
    width: calc(100% - 55%);

  }
   
  .projcard:nth-child(2n) .projcard-textbox {
    left: initial;
    right: 32vw;
  }
   
  .projcard-textbox::before,
  .projcard-textbox::after {
    content: "";
    position: absolute;
    display: block;
    background: #ff0000bb;
    background: #fff;
    top: -20%;
    left: -4vw;
    height: 140%;
    width: 4vw;
    transform: rotate(8deg);
  }
   
  .projcard:nth-child(2n) .projcard-textbox::before {
    display: none;
  }
   
  .projcard-textbox::after {
    display: none;
    left: initial;
    right: -4vw;
  }
   
  .projcard:nth-child(2n) .projcard-textbox::after {
    display: block;
  }
   
  .projcard-textbox * {
    position: relative;
  }
   
  .projcard-title {
    font-size: 1.6vw;
  }
   
   
  .projcard-bar {
  
    width: 6vw;
    height: 5px;
    margin: 0.8vw 0;
    border-radius: 5px;
    background-color: #424242;
    transition: width 0.2s ease;
  }
   
  .projcard-blue .projcard-bar {
    background-color: #0088FF;
  }
   
  .projcard-blue::before {
    background-image: linear-gradient(-70deg, #0088FF, transparent 50%);
  }
   
  .projcard-blue:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #0088FF, transparent 50%);
  }
   
  .projcard-red .projcard-bar {
    background-color: #D62F1F;
  }
   
  .projcard-red::before {
    background-image: linear-gradient(-70deg, #D62F1F, transparent 50%);
  }
   
  .projcard-red:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #D62F1F, transparent 50%);
  }
   
  .projcard-green .projcard-bar {
    background-color: #40BD00;
  }
   
  .projcard-green::before {
    background-image: linear-gradient(-70deg, #40BD00, transparent 50%);
  }
   
  .projcard-green:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #40BD00, transparent 50%);
  }
   
  .projcard-yellow .projcard-bar {
    background-color: #F5AF41;
  }
   
  .projcard-yellow::before {
    background-image: linear-gradient(-70deg, #F5AF41, transparent 50%);
  }
   
  .projcard-yellow:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #F5AF41, transparent 50%);
  }
   
  .projcard-orange .projcard-bar {
    background-color: #FF5722;
  }
   
  .projcard-orange::before {
    background-image: linear-gradient(-70deg, #FF5722, transparent 50%);
  }
   
  .projcard-orange:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #FF5722, transparent 50%);
  }
   
  .projcard-brown .projcard-bar {
    background-color: #C49863;
  }
   
  .projcard-brown::before {
    background-image: linear-gradient(-70deg, #C49863, transparent 50%);
  }
   
  .projcard-brown:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #C49863, transparent 50%);
  }
   
  .projcard-grey .projcard-bar {
    background-color: #424242;
  }
   
  .projcard-grey::before {
    background-image: linear-gradient(-70deg, #424242, transparent 50%);
  }
   
  .projcard-grey:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #424242, transparent 50%);
  }
   
  .projcard-customcolor .projcard-bar {
    background-color: var(--projcard-color);
  }
   
  .projcard-customcolor::before {
    background-image: linear-gradient(-70deg, var(--projcard-color), transparent 50%);
  }
   
  .projcard-customcolor:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, var(--projcard-color), transparent 50%);
  }
   
  .projcard-description {
    margin: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.3;
    z-index: 10;
    font-size: 1.12vw;
    color: #424242;

   
  }
   
