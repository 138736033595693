.education-section {
  background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
  width: 100%;
  display: flex;
  align-items: center;
  height: 33vw;
  justify-content: center;

  padding: 0px 6vw;
}
.education-header >h1{
  font-size: 3vw;
  text-align: center;
  color: white;
}
.education-header >p{
  line-height: 2.2vw;
    font-size: 1.6vw;
    font-weight: 600;
    color: #d0d0d0;
    text-align: center;
    width: 80vw;
 
}
@media screen and (min-device-width: 320px) and (max-device-width: 480px){
  .education-section{
    height: 65vw;
    background-size: cover;
  }
  .education-header >h1{
    font-size: 5vw;
  }
  .education-header >p{
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
}
