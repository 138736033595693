.core-v-main-cnt{
    width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1vw 1vw 8vw 1vw ;
    /* background-color: aqua; */
    /* height: 70vh; */

}
.core-v-title-cnt>h2{
    margin-bottom: 2vw;
    font-size: 3vw;
}
.core-v-items-cnt{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.core-text-item{
    height: 100%;
    width: 30%;
    padding:  0vw 2vw;
    gap:8vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  
}
.core-item-cnt-text>p{
    font-size: 1.4vw;
    line-height: 1.6vw;
}
.core-img-item{
    width: 40%;
}
.core-img-item>img{
    width: 100%;
  
}
.core-item-cnt-text{
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}