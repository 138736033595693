.mobile-gsap-home{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mob-text-intro{
    width: 100%;
    max-height: 35vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5vw;
}
.mob-text-intro>p{
    text-align: center;
    font-size: 5vw;
}
.studios-mobile{
    width: 100%;
    display: flex;
    padding: 5vw;
    gap: 8vw;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;

}
.studios-card-item{
    padding: 1vw;
    width: 100%;

    /* background-color: green; */
}
.studio-card-img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 90vw; */
    /* background-color: coral; */
    /* background-color: red; */
    border-radius: 8vw;
}
.studio-card-img >img{
    width:70%;
    border-radius: 8vw;
    /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.studio-card-text{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vw;
    text-align: center;
}
.studio-card-text>p{
    margin-top: 2vw;
    font-weight: 600;
    font-size: 5vw;
    color: rgb(56, 56, 56);
}
.studio-card-text>h1{
 margin: 0px;
     font-size: 7.8vw;
    font-weight: 800;
    /* text-shadow: 2px 3px 5px rgba(0,0,0,0.5); */
}