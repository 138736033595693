.crv-main-cnt{
    width: 100%;
    background-color: rgb(255, 255, 255);
padding: 4vw 0vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.crv-core-head{

}
.crv-core-head>h1{
color: #1a1a1a;
font-size: 5vw;
margin: 0px 0px 2vw 0px;

}
.list-items-core{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 5vw;
}
.crv-core-items{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 width: 12vw;

   
}

.crv-core-items>h5{
    color: #161616;
    font-size: 2vw;
    margin: 1vw;
}
.crv-core-items>img{
    width: 60%;
    object-fit: contain;
}