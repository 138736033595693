/* 


    
    .footer-section {
      background: #151414;
      position: relative;
    }
    
    .container-footer {
      width: 100%;

      margin: 0 auto;
      padding: 0 20px;
    }
    .footer-log-image-section{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .footer-cta {
      border-bottom: 1px solid #373636;
      padding: 20px 0;
    }
    
    .footer-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    
    .find-us-text{
      visibility: hidden;
    }
    .single-cta i {
      color: #ff5e14;
      font-size: 30px;
      float: left;
      margin-top: 8px;
    }
    
    .cta-text {
      padding-left: 50px;
    }
    
    .cta-text h4 {
      color: #fff;
      font-size: 1.5vw;
      font-weight: 600;
      margin-bottom: 2px;
    }
    
    .cta-text span {
      color: #8c8c8c;
      font-size: 1vw;
      line-height: 1.5vw;
    }
    
    .footer-content {
      position: relative;
      z-index: 2;
      padding: 20px 0;
    }
    
    .footer-logo {
      margin-bottom: 30px;
    }
    
    .footer-logo img {
      max-width: 200px;
    }
    
    .footer-text p {
      margin-bottom: 14px;
      font-size: 14px;
      color: #7e7e7e;
      line-height: 28px;
    }
    
    .footer-social-icon span {
      color: #fff;
      display: block;
      font-size: 20px;
      font-weight: 700;
      font-family: 'Poppins', sans-serif;
      margin-bottom: 20px;
    }
    
    .footer-social-icon a {
      color: #fff;
      font-size: 16px;
      margin-right: 15px;
    }
    
    .footer-social-icon i {
      height: 40px;
      width: 40px;
      text-align: center;
      line-height: 38px;
      border-radius: 50%;
    }
    
    .facebook-bg {
      background: #3B5998;
    }
    
    .twitter-bg {
      background: #55ACEE;
    }
    
    .google-bg {
      background: #DD4B39;
    }
    
    .footer-widget-heading h3 {
      color: #fff;
      font-size: 2vw;
      font-weight: 600;
      margin-bottom: 40px;
      position: relative;
    }
    
    .footer-widget-heading h3::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -15px;
      height: 2px;
      width: 50px;
      background: #ff5e14;
    }
    
    .footer-widget ul {
      list-style: none;
      padding: 0;
    }
    
    .footer-widget ul li {
      margin-bottom: 12px;
    }
    
    .footer-widget ul li a {
      font-size: 1vw;
      color: #878787;
      text-transform: capitalize;
    }
    
    .footer-widget ul li a:hover {
      color: #ff5e14;
    }
    
    .subscribe-form {
      position: relative;
      overflow: hidden;
    }
    
    .subscribe-form input {
      width: calc(100% - 60px);
      padding: 14px 20px;
      background: #2E2E2E;
      border: 1px solid #2E2E2E;
      color: #fff;
      float: left;
    }
    
    .subscribe-form button {
      background: #ff5e14;
      padding: 13px 20px;
      border: 1px solid #ff5e14;
      float: right;
    }
    
    .subscribe-form button i {
      color: #fff;
      font-size: 22px;
      transform: rotate(-6deg);
    }
    
    .copyright-area {
      background: #202020;
      padding: 15px 0;
    }
    
    .copyright-text p {
      margin: 0;
      font-size: 1vw;
      color: #878787;
    }
    
    .copyright-text p a {
      color: #ff5e14;
    }
    
    .footer-menu ul {
      list-style: none;
      padding: 0;
    }
    
    .footer-menu li {
      display: inline-block;
      margin-left: 20px;
    }
    
    .footer-menu li a {
      font-size: 14px;
      color: #878787;
    }
    
    .footer-menu li:hover a {
      color: #ff5e14;
    }
     */


     .footer-section {
      background: #151414;
      position: relative;
    }
    
    .container-footer {
      width: 100%;
      margin: 0 auto;
      padding: 0 1.5vw;
    }
    .footer-log-image-section{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .footer-cta {
      /* border-bottom: 1px solid #373636; */
      padding: 1.5vw 0vw;
    }
    
    .footer-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
    }
    
    .find-us-text{
      visibility: hidden;
    }
 
    .single-cta i {
      color: #ff5e14;
      font-size: 1.8vw;
      float: left;
      margin-top: 0.6vw;
    }
    
    .cta-text {
      padding-left: 4vw;
    }
    
    .cta-text h4 {
      color: #fff;
      font-size: 1.5vw;
      font-weight: 600;
      margin-bottom: 0.8vw;
    }
    
    .cta-text span {
      color: #8c8c8c;
      font-size: 1vw;
      line-height: 1.5vw;
    }
    
    .footer-content {
      position: relative;
      z-index: 2;
      padding: 1vw 0vw;
    }
    
    .footer-logo {
      margin-bottom: 2vw;
    }
    
    .footer-logo img {
      max-width: 16vw;
    }
    
    .footer-text p {
      margin-bottom: 0.5vw;
      font-size: 0.5vw;
      color: #7e7e7e;
     
    }
    
  
 
    
 
    
 
    
    .footer-widget-heading h3 {
      color: #fff;
      font-size: 2vw;
      font-weight: 600;
      margin-bottom: 2vw;
      position: relative;
    }
    
    .footer-widget-heading h3::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -0.8vw;
      height: 0.15vw;
      width: 4vw;
      background: #ff5e14;
    }
    
    .footer-widget ul {
      list-style: none;
      padding: 0;
    }
    
    .footer-widget ul li {
      margin-bottom: 0.6vw;
    }
    
    .footer-widget ul li a {
      font-size: 1vw;
      color: #878787;
      text-transform: capitalize;
    }
    
    .footer-widget ul li a:hover {
      color: #ff5e14;
    }
    
    .subscribe-form {
      position: relative;
      overflow: hidden;
    }
    
  
  
    
 
    
    .copyright-area {
      background: #202020;
      padding: 0.5vw 0;
    }
    
    .copyright-text p {
      margin: 0;
      font-size: 1vw;
      color: #878787;
    }
    
    .copyright-text p a {
      color: #ff5e14;
    }
    
    .footer-menu ul {
      list-style: none;
      padding: 0;
    }
    .footer-cnt-1{
      width: 50%;
      display: flex;
      /* align-items: center; */
      justify-content: space-around;

    }
    
 
    
 
    
  
    
 
    @media (min-width: 320px) and (max-width: 480px) {
     .footer-widget ul li{
      margin-bottom: 0px;
      /* font-size: 3vw; */
     }
     .footer-widget-heading h3{
      margin-bottom: 2vw;
      font-size: 5vw;
     }
     .footer-widget ul li a{
      font-size: 3vw;
     }
    
     .footer-widget ul{
      margin: 0px;
     }
     .find-us-text{
    margin: 0px;
     }
   .footer-row>*{
    flex:2 2
   }
   .cta-text{
    line-height: 1px;
   }
   .single-cta i{
    font-size: 3vw;
    margin-top: 1.3vw;
    margin-left:-2.6vw;
    
   }
   .footer-row{
    flex-direction: column;
    gap: 5vw;
   }
   .footer-cnt-1{
    width: 100%;
    display: flex;
  
    justify-content: space-around;

  }
  .cta-text span{
    font-size:3vw;
    line-height: 3vw;
  }
  .copyright-text p{
    font-size: 3vw;
    text-align: center;
  }
  .copyright-area{
    padding: 2.5vw 0vw;
  }
  .footer-logo img{
    max-width: 30vw;
  }
  .cta-text h4{
    font-size: 5vw;
    margin-bottom: 2vw;
  }

      }





      @media (min-width: 380px) and (max-width: 479px) {
         
  
      }