/* src/components/ConnectWithUs.css */
.xd-hero-container{
  /* margin-top: 70px; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: #f3f3f3;
}

.xd-hero-img-cc{
  position: relative;
  width: 100%;

}

.xd-hero-img-cc img {
  width: 100%;
  height: auto;
  display: block;
}

.xd-hero-txt-cnt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-80%, -38%);
  color: white;
  text-align: left;
  width: 55vw;
  padding: 20px;
  border-radius: 8px;
}

.head-back-btn{
  position: absolute;
align-items: center;
justify-content: left;
  display: flex;
  margin-top: -9vw;
  color: rgb(255 255 255 / 50%);
}
.head-back-btn>h5{
  font-size: 1.5vw;
  font-weight: 400;
}
.back-icon-btn{
  font-size: 1.5vw;
}

/* .xd-hero-txt-cnt >h1{
  font-weight: 800;
  line-height: 4vw;
  margin-bottom:2vw;
  font-size: 10vw;
  color: #f95932;
} */
.xd-hero-txt-cnt h3 {
  line-height: 2.5vw;
  /* padding: 2vw 0vw; */
  margin:0vw;
  font-size: 2.5vw;
  color: #f3f3f3;
}

  .xd-hero-txt-cnt>h3{
    margin: 0px 0px 2vw 0px;
  }

.xd-hero-txt-cnt p {
  /* margin: 3vw 0;
  font-size: 1.5vw;
  font-weight: 600;
  line-height: 1.8vw; */

  line-height: 2.2vw;
    font-size: 1.6vw;
    font-weight: 600;
    color: rgb(255 255 255 / 70%);
    /* text-align: justify; */
  
}

.xd-hero-txt-cnt button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 0.8vw;
  cursor: pointer;
  font-size: 1.5vw;
  font-weight: 600;
  line-height: 1.8vw;
}

.xd-hero-txt-cnt button:hover {
  background-color: #000000;
  color: white;
  outline: 1px solid white;
}


/* studios items */
.card-st-container{
  padding: 3vw 1vw 1vw 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2vw;

}
.card-sd-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vw;
  width: 80%;
  /* height: 300px; */
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset; */
}
.card-item-img-sd{
  height: 100%;
  width: 25%;
  overflow: hidden;
}
.text-cnt-sd{
  height: 100%;
  width: 65%;
  display: flex;
  flex-direction:column ;
  justify-content: center;
}
.text-cnt-sd>h2{
  margin: 0;
  font-size: 3vw;
  font-weight: 600;
  /* line-height: 1.8vw; */
  color: #f95932;
}
.text-cnt-sd>h3{
  /* margin: 0; */
  font-size: 2vw;
  font-weight: 600;
  line-height: 1.8vw;
  color: #636363;
}
.text-cnt-sd>p{
  /* margin: 3vw 0;
  font-size: 1.5vw;
  font-weight: 600;
  line-height: 1.8vw; */

  line-height: 2.2vw;
    font-size: 1.4vw;
    font-weight: 500;
    color: #000000;
    /* text-align: justify; */
}
.card-item-img-sd{
  display: flex;
  align-items: center;
  justify-content: center;

}

.card-item-img-sd>img{
  width: 100%;
  object-fit: cover;
}
.mb-reverse{
  flex-direction: row-reverse;
}

@media (min-width: 320px) and (max-width: 480px) {
  .xd-hero-img-cc{
    height: 35vh;
    overflow: hidden;
  }
  .xd-hero-img-cc img{
    width: 200%;
    margin-left:-50% ;
  }
  .xd-hero-txt-cnt{
    width: 100%;
    transform: translate(-50%, -50%);
    /* padding: 2vw; */
  }
  .xd-hero-txt-cnt h3{
    font-size: 6vw;
    line-height: 5vw;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  .xd-hero-txt-cnt p{
    font-size: 4vw;
    font-weight: 400;
    line-height: 4vw;
    color: #e4e4e4;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  .head-back-btn>h5{
    font-size: 2.5vw;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  .back-icon-btn{
    font-size: 2.5vw;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  .card-sd-item{
    width: 100%;
    padding: 4vw;
    flex-direction: column-reverse;
    gap: 5vw;
  }
  .mb-reverse{
    flex-direction: column-reverse;
  }
  .text-cnt-sd{
  width: 90%;
  }
  .card-item-img-sd{
    width: 70%;
    /* display: none; */
  }
  .text-cnt-sd>h2{
    font-size: 6vw;
  }
  .text-cnt-sd>h3{
    font-size: 4vw;
    margin: 2vw 0vw 2vw 0vw;
    
  }
  .text-cnt-sd>p{
    font-size: 4vw;
    line-height: 4vw;
    
  }

}