/* src/Timeline.css */
.timeline-main {
  background-color:#ffffff;
    box-sizing: border-box;
    overflow: hidden;
  }
  .timeline {
    position: relative;
    max-width: 80%;
    margin: 0px auto;
    padding: 2vw 0;
  }
  .timeline-heading-key {
    width: 100%;
    padding: 1vw;
    text-align: center;
    font-size: 1.3vw;
  }
  .timeline-item {
    padding: 0px;
    position: relative;
    width: 50%;
  }
  .timeline-item.left {
    left: 0;
    text-align: right;
  }
  .timeline-item.right {
    left: 50%;
  }
  .timeline-item .timeline-icon {
    /* border: 4px solid #bcbcbc; */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vw;
    width: 5vw;
    position: absolute;
    /* top: -2.5vw; */
    top: -1.9vw;
    z-index: 1;
  }
  .timeline-icon > img {
    width: 3vw;
    /* mix-blend-mode: difference; */
  }
  .timeline-item.left .timeline-icon {
    right: -2.5vw;
  }
  .timeline-item.right .timeline-icon {
    left: -2.5vw;
  }
  .timeline-item .timeline-content {
    border-radius: 4px;
    position: relative;
    width: calc(100% - 6vw);
  }
  .timeline-content > h2 {
    /* color: #1a1a1a; */
    font-size: 2vw;
    text-align: left;
  }
  .timeline-content > p {
    font-size: 1.6vw;
    line-height: 2vw;
    text-align: left;
    color: #000000b3;
  }
  .timeline-item.left .timeline-content {
    margin-right: 6vw;
  }
  .timeline-item.right .timeline-content {
    margin-left: 6vw;
  }
  .timeline-line {
    background: #bcbcbc;
    height: 100%;
    left: 49.9%;
    position: absolute;
    top: 0;
    width: 0.3vw;
    /* width: 4px; */
    z-index: 0;
  }
  