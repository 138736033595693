.heading-hybrid-apps>h1{
   
    padding: 10px;
    color: white;
  
}
.heading-hybrid-apps{
    background-color: black;
    border-radius: 10px;
}
.Heading-mad-native{
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.Mobile-App-Tech-Bg{
    width: 100%;
 /* height: 500px; */
display: flex;
align-items: center;
justify-content: center; 
 background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;  
  background-image:url(../../../assets/ReactNative-bg.png);
  padding: 30px 20px;
}
.MAT-Rnative-Img{
    display: flex;
    align-items: center;
    justify-content: center;
}
.MAT-Rnative-Text>p{
   
    color: hsla(0,0%,100%,.6);
     font-size: 20px;
    text-align: justify;
    padding: 3px;
    margin-right: 40px
}
.MAT-Rnative-Text{
    width: 50%;
    padding: 15px;
}
.MAT-Rnative-Img>img{
    width: 450px;
}
.MAT-Rnative-Img{
    width: 50%;

}


/* ------------------Responsive----------------------------------- */
@media screen and (min-device-width:1024px) and (max-device-width:1280px){
.Mobile-App-Tech-Bg{
    padding: 25px 18px;

}
.MAT-Rnative-Img>img{
    width: 400px;
}
.MAT-Rnative-Text>p{
    font-size: 17px;

}
}

@media screen and (min-device-width:920px) and (max-device-width:1024px){
    .heading-hybrid-apps>h1{
        font-size: 20px;
    }
    .Mobile-App-Tech-Bg{
        padding: 22px 15px;
    
    }
    .MAT-Rnative-Img>img{
        width: 380px;
    }
    .MAT-Rnative-Text>p{
        font-size: 16px;
        padding: 2px;
    
    }
    }
    @media screen and (min-device-width:768px) and (max-device-width:920px){
        .heading-hybrid-apps>h1{
            font-size: 19px;
        }
        .Mobile-App-Tech-Bg{
            display: flex;
            flex-direction: column;
            padding: 20px 13px;
        
        }
        .MAT-Rnative-Text{
            width: 100%;
            padding: 15px;
        }
        .MAT-Rnative-Img>img{
            width: 350px;
        }
        .MAT-Rnative-Text>p{
            font-size: 15px;
            padding: 2px;
        
        }
        }
   @media screen and (min-device-width:585px) and (max-device-width:768px){
    .heading-hybrid-apps>h1{
        font-size: 18px;
    }
            .Mobile-App-Tech-Bg{
                display: flex;
                flex-direction: column;
                padding: 18px 13px;
            
            }
            .MAT-Rnative-Text{
                width: 100%;
                padding: 15px;
            }
            .MAT-Rnative-Img>img{
                width: 300px;
            }
            .MAT-Rnative-Text>p{
                font-size: 14px;
                padding: 1px;
            
            }
     }

     @media screen and (min-device-width:585px) and (max-device-width:768px){
        .heading-hybrid-apps>h1{
            font-size: 17px;
        }
        .Mobile-App-Tech-Bg{
            display: flex;
            flex-direction: column;
            padding: 18px 13px;
        
        }
        .MAT-Rnative-Text{
            width: 100%;
            padding: 15px;
        }
        .MAT-Rnative-Img>img{
            width: 300px;
        }
        .MAT-Rnative-Text>p{
            font-size: 14px;
            padding: 1px;
        
        }
   }
   @media screen and (min-device-width:480px) and (max-device-width:585px){
    .heading-hybrid-apps>h1{
        font-size: 16px;
    }
    .Mobile-App-Tech-Bg{
        display: flex;
        flex-direction: column;
        padding: 15px;
    
    }
    .MAT-Rnative-Text{
        width: 100%;
        padding: 10px;
    }
    .MAT-Rnative-Img>img{
        width: 280px;
    }
    .MAT-Rnative-Text>p{
        font-size: 13px;
        padding: 1px;
    
    }
    }





@media screen and (min-device-width:380px) and (max-device-width:480px){

    .heading-hybrid-apps>h1{
        font-size: 15px;
    }

.Mobile-App-Tech-Bg{
 display: flex;
 flex-direction: column;
 padding: 10px;



}
.MAT-Rnative-Text{
    width: 100%;
    padding: 6px;
   
 
}
.MAT-Rnative-Text>p{
     font-size: 11px;
     padding: 1px;
}
.MAT-Rnative-Img>img{
    width: 250px;
}

}
 @media screen and (min-device-width:320px) and (max-device-width:380px){

    .heading-hybrid-apps>h1{
        font-size: 15px;
    }

.Mobile-App-Tech-Bg{
 display: flex;
 flex-direction: column;
 padding: 10px;



}
.MAT-Rnative-Text{
    width: 100%;
    padding: 6px;
   
 
}
.MAT-Rnative-Text>p{
     font-size: 10px;
     padding: 1px;
}
.MAT-Rnative-Img>img{
    width: 220px;
}

}





/* ------------------Flutter ----------------------------------- */
.Mobile-App-Tech-Flutter-Bg{
    background-image:url(../../../assets/Flutter-bg.png);
    width: 100%;
    /* height: 500px; */
    display: flex;
    align-items: center;
    justify-content: center; 
     background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative; 
      padding: 30px 20px; 

}
.MAT-Flutter-Img>img{
    width: 400px;
}
.MAT-Flutter-Img{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MAT-Flutter-Text>p{
    color: hsla(0,0%,100%,.6);
     font-size: 18px;
    text-align: justify;
    padding: 3px;

}
.MAT-Flutter-Text{
    padding: 15px;
    width: 50%;
}



/* -----------------Responsive------------ */


@media screen and (min-device-width:1024px) and (max-device-width:1280px){
    .Mobile-App-Tech-Flutter-Bg{
        padding: 25px 18px;
 }

 .MAT-Flutter-Img>img{
    width: 350px;
}
.MAT-Flutter-Text>p{
   font-size: 17px;
    padding: 2;
}

}

@media screen and (min-device-width:920px) and (max-device-width:1024px){
    .Mobile-App-Tech-Flutter-Bg{
        padding: 22px 15px;
 }

 .MAT-Flutter-Img>img{
    width: 320px;
}
.MAT-Flutter-Text>p{
   font-size: 16px;
    padding: 2;
}
}

@media screen and (min-device-width:768px) and (max-device-width:920px){
    .Mobile-App-Tech-Flutter-Bg{
        padding: 20px 10px;
        display: flex;
        flex-direction: column-reverse;
 }
 .MAT-Flutter-Text{
    width: 100%;
    padding: 10px 15px;
 }

 .MAT-Flutter-Img>img{
    width: 270px;
}
.MAT-Flutter-Text>p{
   font-size: 15px;
    padding: 2;
}
}
@media screen and (min-device-width:585px) and (max-device-width:768px){
    .Mobile-App-Tech-Flutter-Bg{
        padding: 15px 10px;
        display: flex;
        flex-direction: column-reverse;
 }
 .MAT-Flutter-Text{
    width: 100%;
    padding: 10px;
 }

 .MAT-Flutter-Img>img{
    width: 220px;
}
.MAT-Flutter-Text>p{
   font-size: 14px;
    padding: 1;
}

}
@media screen and (min-device-width:480px) and (max-device-width:585px){
    .Mobile-App-Tech-Flutter-Bg{
        padding:10px;
        display: flex;
        flex-direction: column-reverse;
 }
 .MAT-Flutter-Text{
    width: 100%;
    padding: 10px;
 }

 .MAT-Flutter-Img>img{
    width: 200px;
}
.MAT-Flutter-Text>p{
   font-size: 13px;
    padding: 1;
}
}


@media screen and (min-device-width:380px) and (max-device-width:480px){

    .Mobile-App-Tech-Flutter-Bg{
       display: flex;
       flex-direction: column-reverse;
       padding: 10px;
    
    }
    .MAT-Flutter-Img>img{
        width: 180px;
    }
    .MAT-Flutter-Text>p{
     
         font-size: 10px;
          padding: 1px;
    
    }
    .MAT-Flutter-Text{
        width: 100%;
        padding: 6px;
    }
    

}

@media screen and (min-device-width:320px) and (max-device-width:380px){

    .Mobile-App-Tech-Flutter-Bg{
       display: flex;
       flex-direction: column-reverse;
       padding: 10px;
    
    }
    .MAT-Flutter-Img>img{
        width: 150px;
    }
    .MAT-Flutter-Text>p{
     
         font-size: 9px;
          padding: 1px;
    
    }
    .MAT-Flutter-Text{
        width: 100%;
        padding: 6px;
    }
    

}



