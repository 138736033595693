.containerSplash2 {
  color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.containerSplash2 {
  font-size: 35px;
}


.Home-main-container {
  width: 100%;
  height: 100vh;
  /* scroll-snap-type: y mandatory; */
  box-sizing: border-box;

  flex-direction: column;
  /* overflow: scroll; */
 
}
.display-mobile-items{
  display: none;
}
.bg-cli-logo{
  background-color: #f3f3f3;
}

/* .Home-main-container > .scroll222 {
  scroll-snap-align: start;
  scroll-snap-align: top 0px;
  box-sizing: border-box;
  overflow: hidden;
} */
.hero-logo-text {
  height: 100vh;
}

.avs {
  height: 100vh;
  width: 100%;
  background-color: #fff;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.Home-main-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Home-main-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.Home-tex > h1 {
  font-family: "mr-eaves-xl-modern-narrow", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.navbar-container {
  position: fixed;

  width: 100%;

  background-color: #333; /* Example background color */
  transition: transform 0.9s ease-in-out; /* Add smooth transition to the transform property */
}

.visible {
  transform: translateY(0); /* Show the navbar */
}

.hidden44 {
  transform: translateY(-100%); /* Hide the navbar */
}

.heroLogoImg {
  width: 50%;
  transition: width 0.3s ease; /* Add smooth transition */
}

.sticky-image {
  width: 12%;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}
/* __________________________________________________________ */
/* MEDIA QUARIES MEDIA QUARIES  MEDIA QUARIES  MEDIA QUARIES  */
/* ---------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 379px) {
  .containerSplash2{
    height: auto;
  }
  
  .containerSplash2 > img {
    width: 200px;
  }
  .containerSplash2 > h3 {
    font-size: 20px;
  }
  .containerSplash2 {
    text-align: center;
  }
  .Home-text {
    padding: 0px 15px;
  }
  .Home-text > h1 {
    font-size: 24px;
    line-height: 50px;
  }
  .studiocont {
    font-size: 25px;
  }
  .hoverCards12 {
    height: auto;
  }
  .studiocont{
    height: 50vh;
  }
  .display-mobile-items{
    display: block;
  }
  .display-mobile{
    display: none;
  }
}
/* ---------------------------------------------------------- */

@media (min-width: 380px) and (max-width: 479px) {
  .containerSplash2{
    height: auto;
  }

  .containerSplash2 > img {
    width: 200px;
  }
  .containerSplash2 > h3 {
    font-size: 20px;
  }
  .containerSplash2 {
    text-align: center;
  }
  .Home-text {
    padding: 0px 15px;
  }
  .Home-text > h1 {
    font-size: 30px;
    line-height: 50px;
  }
  .studiocont {
    font-size: 32px;
  }
  .hoverCards12 {
    height: auto;
  }
  .studiocont{
    height: 60vh;
  }
.display-mobile{
  display: none;
}
.display-mobile-items{
  display: block;
}
}

/* ---------------------------------------------------------- */

@media (min-width: 480px) and (max-width: 576px) {
}

/* ---------------------------------------------------------- */

@media (min-width: 576px) and (max-width: 768px) {
}

/* ---------------------------------------------------------- */

@media (min-width: 769px) and (max-width: 992px) {
}

@media (min-width: 769px) and (max-width: 992px) {
}
@media (min-width: 1280px) and (max-width: 1340px) {
  .containerSplash2 > img {
    width: 55%;
  }
  .containerSplash2 > h3 {
    font-size: 35px;
  }
}
/* ---------------------------------------------------------- */
