/* src/HeroSection.css */

.hero-section-art-h {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background: linear-gradient(to right, #1e3c72, #2a5298);
    color: white;
    text-align: center;
    padding: 0 20px;

    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .hero-content-art-h {
    max-width: 80%;
  }
  
  .hero-title-art-h {
    font-size: 3.5vw;
    margin-bottom: 2vw;
  }
  
  .hero-subtitle-art-h {
    font-size: 2vw;
 
  }
  
  .hero-button-art-h {
    padding: 10px 20px;
    font-size: 1rem;
    color: #1e3c72;
    background: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .hero-button-art-h:hover {
    background: #f1f1f1;
  }
  
  /* src/InfoSection.css */

.info-section-art-h {
    padding: 50px 20px;
    background-color: #f5f5f5;
    color: #333;
  }
  
  .info-content-art-h {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .info-title-art-h {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #1e3c72;
  }
  
  .info-text-art-h {
    font-size: 1.2rem;
    margin-bottom: 40px;
    line-height: 1.6;
  }
  
  /* src/KeyComponentsSection.css */
/* src/KeyComponentsSection.css */

.key-components-section-art-h {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    background-color: #fff;
    color: #333;
  }
  
  .key-components-title-art-h {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 40px;
    color: #1e3c72;
  }
  
  .key-components-grid-art-h {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .key-component-card-art-h {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    width: 300px; /* Fixed width for all cards */
    height: 300px; /* Fixed height for all cards */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .key-component-title-art-h {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #1e3c72;
  }
  
  .key-component-text-art-h {
    font-size: 1.2vw;
    color: #666;
  }
  

  /* src/BenefitsSection.css */

.benefits-section-art-h {
    padding: 50px 20px;
    background-color: #fff;
    color: #333;
  }
  
  .benefits-title-art-h {
    font-size: 2vw;
    text-align: center;
    color: #1e3c72;
  }
  
  .benefits-grid-art-h {
    display: flex;
  flex-wrap: wrap;
    justify-content: center;
    gap: 1vw;
  }
  
  .benefits-column-art-h {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /* flex-direction: column; */
    gap: 1vw;
    flex: 1;
    /* max-width: 400px; */
  }
  
  .benefit-item-art-h {
    width: 22vw;
    height: 10vw;
    padding: 1vw;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
  }
  
  .benefit-title-art-h {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #1e3c72;
  }
  
  .benefit-text-art-h {
    font-size: 1.2vw;
    color: #666;
  }
  


  /* src/MiddleBanner.css */

.middle-banner-art-h {
    padding: 3vw 3vw 5vw 3vw ;
    background-color: #1e3c72;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .middle-banner-content-art-h {
    max-width: 90%;
  }
  
  .middle-banner-title-art-h {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #f5f5f5;
  }
  
  .middle-banner-points-art-h {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
  }
  
  .middle-banner-point-art-h {
  


    text-align: left;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    color: #ffffff;
  }
  
  .middle-banner-point-title-art-h {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #e1e1e1;
  }
  
  .middle-banner-point-text-art-h {
    font-size: 1.2vw;
    color: #e1e1e1;
  }
  

  /* src/EnableIndustry4_0.css */

.enable-industry4-0-art-h {
    padding: 50px 20px;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .enable-industry4-0-content-art-h {
    max-width: 90%;
    margin: 0 auto;
  }
  
  .enable-industry4-0-title-art-h {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
    color: #1e3c72;
  }
  
  .enable-industry4-0-description-art-h {
    font-size: 1.5vw;
    margin-bottom: 3vw;
    text-align: center;
    color: #444444;
  }
  
  .enable-industry4-0-points-art-h {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .enable-industry4-0-point-art-h {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .enable-industry4-0-icon-art-h {
  padding: 2vw;
    font-size: 5vw;
    color: #1e3c72;
    margin-right: 15px;
  }
  
  .enable-industry4-0-text-art-h {
    flex: 1;
  }
  
  .enable-industry4-0-point-title-art-h {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: #1e3c72;
  }
  
  .enable-industry4-0-point-text-art-h {
    font-size: 1.2vw;
    line-height: 1.2;
    color: #393939;
  }


  /* src/CaseStudiesConclusionCallToAction.css */
/* src/CaseStudiesConclusionCallToAction.css */

.case-studies-conclusion-call-to-action-art-h {
    padding: 50px 20px;
    background-color: #1e3c72; /* Dark blue background */
    text-align: center;
  }
  
  .section-title-art-h {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ffffff; /* White text */
  }
  
  .section-description-art-h {
    font-size: 1.4vw;
    color: #ececec;
    margin-bottom: 40px;
  }
  
  .points-section-art-h {
    display: flex;
align-items: center;
justify-content: center;
    align-items: center;
    gap: 40px;
  }
  
  .point-item-art-h {
    display: flex;
    align-items: center;
    /* background-color: #fff; */
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* max-width: 45vw;
    text-align: left; */
    gap: 20px;
  }
  
  .point-icon-art-h {
    font-size: 7vw;
    color: #ffffff; /* Dark blue icon */
  }
  
  .point-content-art-h {
    flex: 1;
  }
  
  .point-title-art-h {
    font-size: 2vw;
    margin-bottom: 10px;
    color: #e4e4e4; /* Dark blue text */
  }
  
  .point-description-art-h {
    font-size: 1.2vw;
    color: #d7d7d7;
  }
  