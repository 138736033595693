.hero {
	z-index: 3;
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	overflow: hidden;
	color: #fff;
	box-sizing: border-box;
	overflow: hidden;
  }
  
  .flashing-text {
	list-style: none;
	color: #fff;
  }
  
  .background-video {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transform: translate(-50%, -50%);
  }
  
  .container {
	position: relative;
	z-index: 1;
	padding: 2vw;
	width: 100%;
	text-align: center;
  }
  
  .container h1 {
	font-size: 4vw;
	font-weight: bold;
	margin-bottom: 1rem;
	opacity: 0;
	animation: fadeIn 2s forwards;
	animation-delay: 0.5s;
  }
  
  .para-hero-home {
	margin-top: 3vw;
	position: relative;
	width: 80%;
  }
  
  .para-hero-home > p {
	line-height: 2.2vw;
	font-size: 1.6vw;
	font-weight: 600;
	color: #d0d0d0;
	text-align: justify;
	margin-bottom: 2vw;
  }
  
  @keyframes fadeIn {
	from {
	  opacity: 0;
	  transform: translateY(-2.5vw);
	}
	to {
	  opacity: 1;
	  transform: translateY(0);
	}
  }
  
  .container99 {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  }
  
  .heading99 {
	font-size: 4vw;
	margin-bottom: 2vw;
	position: relative;
  }
  
  .paragraph-container99 {
	position: relative;
	height: 5vw;
  }
  
  .paragraph99 {
	font-size: 3vw;
	color: white;
	margin: 0;
	opacity: 0;
	transition: opacity 1s, transform 1s;
	transform: translateY(2.5vw);
  }
  
  .fade-in {
	opacity: 1;
	transform: translateY(0);
	color: white;
  }
  
  .fade-out {
	opacity: 0;
	transform: translateY(-2.5vw);
	color: black;
  }


   /* responsive 320px to 380px */
  
   @media screen and (min-device-width: 320px) and (max-device-width: 380px){
	.hero {
        height: 70vh;
    }
	.heading99{
		font-size: 8vw;
		margin-top: 20vw;
	}
	.paragraph99{
		font-size: 5vw;
	}
	.para-hero-home{
		margin-top: 7vw;
		width: 90%;
	}
	.para-hero-home > p{
		font-size: 3vw;
		text-align: center;
		line-height: 1.28;
		font-weight: 400;

	}

   }
  /* responsive 380px to 480px */
  
  @media screen and (min-device-width: 380px) and (max-device-width: 480px){
	.hero {
        height: 50vh;
    }
	.heading99{
		font-size: 8vw;
		margin-top: 20vw;
	}
	.paragraph99{
		font-size: 5vw;
	}
	.para-hero-home{
		margin-top: 7vw;
		width: 90%;
	}
	.para-hero-home > p{
		font-size: 3vw;
		text-align: center;
		line-height: 1.28;
		font-weight: 400;

	}

  }