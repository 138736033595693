/* src/components/ConnectWithUs.css */
.main-sd-hero-container{
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: #f3f3f3;
  }
  
  .main-sd-hero-img-cc{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
  
  .main-sd-hero-img-cc img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .main-sd-hero-txt-cnt {
    position: absolute;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    color: white;
    text-align: left;
    width: 70vw;
    padding: 20px;
    border-radius: 8px;
  }
  .main-sd-hero-txt-cnt >h1{
    text-align: center;
    font-weight: 800;
    line-height: 4vw;
    margin-bottom:2vw;
    font-size: 5vw;
    color: #f95932;
  }
  .main-sd-hero-txt-cnt h3 {
    line-height: 4vw;
    padding: 2vw 0vw;
    margin:0vw;
    font-size: 3.6vw;
    color: #f3f3f3;
  }
  
  .main-sd-hero-txt-cnt p {
    /* margin: 3vw 0;
    font-size: 1.5vw;
    font-weight: 600;
    line-height: 1.8vw; */
  text-align: center;
    line-height: 2.8vw;
      font-size: 2.3vw;
      font-weight: 600;
      color: #ffffff;
      /* text-align: justify; */
    
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .main-sd-hero-container{
      height: 35vh;
    }
    .main-sd-hero-img-cc img{
      width: 180%;
    }
    .main-sd-hero-txt-cnt p{
      font-size: 4vw;
      line-height: 4vw;
      font-weight: 400;
    }
    .main-sd-hero-txt-cnt >h1{
      font-size: 8vw;
      margin-bottom: 5vw;
    }
    .main-sd-hero-txt-cnt{
      width: 100%;
    }
    .studios-section-home{
padding: 4vw 0vw;
gap: 3vw;
    }
    .studio-card{
      width: 45vw;
      height: 67vw;
    }
    .studio-info{
      padding: 0vw 3.5vw;
    }
    .studio-info>h3{
      font-size: 4vw;
    }
    .studio-info>p{
      font-size: 3.1vw;
    }
  }








  