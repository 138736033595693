.health-fitness-img{
  background-image: url(../../../assets/Medical\ BG.jpg);
  width: 100%;
  height: 33vw;
  background-size: 100%;
  display: flex;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  padding: 0px 6vw;
  box-sizing: border-box;
  overflow: hidden;

}

.health-fitness-styling{
  padding: 20px 90px;
}
.health-fitness-styling >h1{
  font-size: 3vw;
  text-align: center;
  color: white;
}
.health-fitness-styling >p{
  line-height: 2.2vw;
    font-size: 1.6vw;
    font-weight: 600;
    color: #d0d0d0;
    text-align: center;
    width: 80vw;

}

@media screen and (min-device-width: 320px) and (max-device-width: 480px){
  .health-fitness-img{
    height: 65vw;
    background-size: cover;
    padding: 0px 1vw;
  }
  .health-fitness-styling{
    padding: 0px;
  }
  .health-fitness-styling >h1{
    font-size: 5vw;
  }
  .health-fitness-styling >p{
    font-size: 3.5vw;
    line-height: 3.5vw;

  }
}
