
.client-logos-title{
    margin: 0px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    background-color:#ffffff;
    overflow: hidden;
    /* mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0)
    ); */

}
.client-logos-title>h1{
    margin: 0px;
    margin-top: 2vw;
font-size: 3vw;
}
.wrapper-client {
    width: 100%;
    /* max-width: 1536px; */
    margin-inline: auto;
    flex-direction: column;
    position: relative;
    background-color:#ffffff;
    height: 8.5vw;
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    /* mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0)
    ); */
  }
   
  @keyframes scrollLeft {
    to {
      left: -200px;
    }
  }

  .item-client4 ,.item-client5 {
width: 12vw;
}
.item-client4>img{
    width: 7vw;
}
.item-client5>img{
    width: 8vw;
}
   
  .item-client {
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12vw;
    height: 6vw;
    background-color:#ffffff;
    position: absolute;
    left: max(calc(200px * 8), 100%);
    animation-name: scrollLeft;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
   .item-img-client{
    width: 100%;
    object-fit: contain;
    mix-blend-mode: difference;

   }
  .item-client1 {
    animation-delay: calc(30s / 8 * (8 - 1) * -1);
  }
   
  .item-client2 {
    animation-delay: calc(30s / 8 * (8 - 2) * -1);
  }
   
  .item-client3 {
    animation-delay: calc(30s / 8 * (8 - 3) * -1);
  }
   
  .item-client4 {
    animation-delay: calc(30s / 8 * (8 - 4) * -1);
  }
   
  .item-client5 {
    animation-delay: calc(30s / 8 * (8 - 5) * -1);
  }
   
  .item-client6 {
    animation-delay: calc(30s / 8 * (8 - 6) * -1);
  }
   
  .item-client7 {
    animation-delay: calc(30s / 8 * (8 - 7) * -1);
  }
   
  .item-client8 {
    animation-delay: calc(30s / 8 * (8 - 8) * -1);
  }
  @media screen and (min-device-width: 320px) and (max-device-width: 480px){
    .item-client1{
      /* left: max(calc(200px * 1), 100%); */
      left: max(calc(200px* 1), 100%);
    }
    .item-client{
      width: 15vw;
    }
    .wrapper-client{
      height: 14vw;
    }

  }