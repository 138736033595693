/* Map.css */
.map-video-container-ept {
  padding: 2vw 0vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeeef;
}

.map-title-ept {
  width: 65%;
}

.map-title-ept > img {
  width: 100%;
}

.video-map-ept {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dc-main-head-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.dc-main-head-text > h1 {
  color: #141414;
  font-size: 3vw;
}

.xd-hero-txt-cnt > h4 {
  font-size: 2vw;
  margin: 0px 0px 1vw 0px;
}
.dc-main-head-text > p {
  font-size: 1.5vw;
  color: #141414;
}
.industries-list-container-ept {
  /* position: absolute; */
  top: 10%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1vw;
  z-index: 1;
  color: rgb(0, 0, 0);
}

.industries-list-container2-ept {
  width: 100%;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: rgb(0, 0, 0);
}

.industry-item2-ept {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.industry-item2-ept > p {
  width: 90%;
  font-size: 1.5vw;
  text-align: center;
  font-weight: 600;
  line-height: 2.5vw;
}

.industry-item-ept {
  background: rgba(255, 255, 255, 0.9);
  width: 15.5vw;
  height: 18vw;
  border-radius: 1vw;
  padding: 1vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.industry-item-ept-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  height: 45%;
}

.industry-item-ept-img > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.industry-item-ept-txt {
  height: 65%;
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
}
.industry-item-ept > img {
  width: 40%;
}
.industry-item-ept-txt > h3 {
  font-size: 1.3vw;
  margin: 0.5vw 0px 0.5vw 0px;
  text-align: center;
}

.industry-item-ept-txt > p {
  width: 90%;
  font-size: 1.1vw;
  text-align: center;
  font-weight: 400;
  line-height: 1.2vw;
  color: #3c3c3c;
}
/* Data Integration end*/

/* Our Business Intelligence & Analytics Solutions */

.an-sol-main-cnt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5vw 0vw;
  gap: 2vw;
}

.an-sol-item-ept {
  background: rgba(255, 255, 255, 0.9);
  width: 18vw;
  height: 19vw;
  border-radius: 1vw;
  padding: 1.5vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.an-sol-item-ept-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  height: 45%;
}
.an-sol-item-ept-img > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.an-sol-item-ept-txt {
  height: 65%;
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
}

.an-sol-item-ept > img {
  width: 40%;
}
.an-sol-item-ept-txt > h3 {
  font-size: 1.5vw;
  margin: 0.5vw 0px 0.5vw 0px;
  text-align: left;
}

.an-sol-item-ept-txt > p {
  /* width: 90%; */
  font-size: 1.2vw;
  text-align: left;
  font-weight: 400;
  line-height: 1.4vw;
  color: #3c3c3c;
}

/* Our Analytics Methodology */

.am-dc-cnt {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: antiquewhite; */
  padding: 3vw;
}
.am-dc-txt-cnt {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  height: 100%;
  padding: 1vw 2vw;
  gap: 1vw;
  /* background-color: aliceblue; */
}
.am-dc-img-cnt {
  width: 40%;
  height: 100%;
  /* background-color: bisque; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.am-dc-item-text {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.am-dc-img-cnt > img {
  width: 100%;
  object-fit: contain;
}

.am-dc-item-text > h5 {
  font-size: 1.6vw;
  margin: 0px 0px 1vw 0px;
}
.am-dc-item-text > p {
  font-size: 1.4vw;
  margin: 0px 0px 12px 0px;
  font-weight: 400;
  color: #3c3c3c;
}

/* Implementation Options: */

.impl-dc-main-ct {
  width: 100%;
  height: 30vw;
  background-image: url(../../assets/integrationBG.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-color: #3c3c3c; */
  padding: 3vw 1.5vw;
  gap: 1.5vw;
  display: flex;
}
.impl-card-items {
  width: 65%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}
.impl-right-img {
  width: 35%;
  height: 100%;
}
.impl-txt-23>h3{
  margin-top: 3vw;
}
.impl-card-item {
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 19.5vw;
  height: 100%;
  padding: 1.5vw;
  background-color: #fff;
}
.impl-img-icon {
  width: 100%;
  height: 40%;
  /* background-color: aliceblue; */
}
.impl-img-icon > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.impl-txt-22 {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.impl-txt-22 > h3 {
  margin: 1vw 0vw;
  font-size: 1.8vw;
  text-align: center;
}

.impl-txt-22 > p {
  font-size: 1.2vw;
  font-weight: 400;
  /* text-align: center; */
}

/* Why Choose Supernal */
.why-choose-cnt {
  /* height: 70vh; */
  /* background-color: aqua; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
}

.why-choose-item {
  padding: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5vw;
  width: 17vw;
  height: 26vw;
  /* background-color: #fff; */
}
.why-choose-img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.why-choose-img > img {
  width: 70%;
  height: 100%;
  object-fit: contain;
}
.why-choose-item-text {
  width: 100%;
}
.why-choose-item-text > p {
  font-size: 1.2vw;
  font-weight: 400;
  text-align: center;
  line-height: 1.5vw;
  color: #3c3c3c;
}




@media (min-width: 320px) and (max-width: 480px) {
  .dc-main-head-text > h1{
    font-size: 6vw;
    text-align: center;
  }
  .dc-main-head-text > p{
    font-size: 5vw;
    text-align: center;
    width: 93%;
  }
.industry-item-ept{
  width: 43vw;
  height: 40vw;
}
.industry-item-ept-txt > h3{
  font-size: 3.5vw;
}
.industry-item-ept-txt > p{
  font-size: 3vw;
  line-height: 3vw;
}
.impl-dc-main-ct{
  height: 60vw;
}
.impl-right-img{
  display: none;
}
.impl-card-items{
  width: 100%;
}
.impl-card-item{
  width: 32vw;
}
.impl-txt-22 > h3{
  font-size: 3.5vw;
}
.impl-txt-22 > p{
  font-size: 2.5vw;
  line-height: 2.5vw;
}
.an-sol-main-cnt{
  flex-wrap: wrap;
}
.an-sol-item-ept{
  padding: 2vw;
  width: 42vw;
  height: 42vw;
}
.an-sol-item-ept-txt > h3{
  font-size: 3.5vw;
  text-align: center;
}
.an-sol-item-ept-txt > p{
  font-size: 2.5vw;
  line-height: 2.5vw;
  text-align: center;
}
.why-choose-cnt{
  flex-wrap: wrap;
  gap: 10vw;

}
.why-choose-item{
  width: 42vw;
  height: 42vw;
  margin-bottom: 10vw;
}
.why-choose-item-text > p{
  font-size: 3.5vw;
  line-height: 3.5vw;
}
.am-dc-cnt{
  flex-direction: column-reverse;
}
.am-dc-txt-cnt{
  width: 100%;
}
.am-dc-img-cnt{
  width: 70%;
}
.am-dc-item-text > h5{
  font-size: 4vw;
}
.am-dc-item-text > p{
  font-size: 3.5vw;
  line-height: 3.5vw;
}
}