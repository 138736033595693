.Navbar_Hamburger{
  background-color: #000000;
   position: sticky;
   top: 0px;
   width: 100%;
   z-index: 2;
   color: white;
   padding: 10px;
 box-sizing: border-box;
  
 }
 .fa-bars, .fa-times{
color: white;
 }
 .Navbar_Hamburger::after{
   content: '';
   display: table;
   clear: both;
 }
 .Navbar_Hamburger ul{
   float:right;
   list-style: none;
   margin-right: 40px;
  
 
 }
 .Navbar_Hamburger ul li{
  color: rgb(255, 255, 255);
   float:left;
   display: block;
   margin: 0 5px;
 }
 .nav-links{
   color: rgb(255, 255, 255);
   text-decoration: none;
   line-height: 70px;
   font-size: 18px;
   padding: 8px 22px;
 }
 .Navbar_Hamburger ul ul{
   position: absolute;
   top: 90px;
   opacity: 0;
   visibility: hidden;
   transition: top .3s;
 
 }

 .Navbar_Hamburger ul li:hover>ul {
  color: rgb(255, 255, 255);
   top: 70px;
   opacity: 1;
   visibility: visible;
 }
 .Navbar_Hamburger ul ul li {
   position: relative;
   margin: 0px;
   width: 150px;
   float: none;
   display: list-item;
  
 }
 .nav_Submenulinks{
   line-height: 50px;
   font-size: 16px;
   color: white;
 }
 .navmenubar_dropdown-items{
   padding: 0px;
 }
 .Navbar_Hamburger ul ul ul li{
   position: relative;
   top: -70px;
  left: 150px;
 }
 .Logo_menu{
   float: left;
   /* padding-left: 15px; */
   width: 180px;
 }
 .icon{
 display: none;
 }
 .show,.Nav_responsive {
   display: none;
 }
 
 @media screen and (min-device-width: 320px) and (max-device-width: 920px){
   .Navbar_Hamburger ul{
       margin-right: 0px;
       float: left;
 
   }
   .Navbar_Hamburger.Logo_menu{
       /* padding-left: 30px; */
       width: 100%;
   }
   .Navbar_Hamburger ul li, .Navbar_Hamburger ul ul li{
       display: block;
       width: 100%;
       /* border-bottom: 1px solid white; */
   }
   .Navbar_Hamburger ul ul {
       top: 50px;
       position: static;
       border-top: none;
       float: none;
       display: none;
       opacity: 1;
       visibility:visible;
   }
   .Navbar_Hamburger ul ul ul li {
       position: static;
 
   }
   .nav_Submenulinks{
     line-height: 40px;

   }
   
 .show{
   display: block;
  font-size: 18px;
   padding: 0 20px;
   line-height: 50px;
   cursor: pointer;
 
 }
 .show:hover{
   /* color:#02164B; */
 
 }
 .nav_dropdown_bg{

   padding: 5px;
   margin-left: 25px;
 
 }
 .icon{
  display: block;
        color: black;
        position: absolute;
        right: 25px;
        line-height: 50px;
        font-size: 28px;
        cursor: pointer;
    
   /* display: block;
   color: black;
   position: absolute;
   right: 40px;
   line-height: 70px;
   font-size: 25px;
   cursor: pointer; */
 }
 
 .Navbar_Hamburger ul li a:hover{
   box-shadow: none;
 }
 .show + a, .Navbar_Hamburger>ul{
   display: none;
 }
  [id^=btn]:checked + ul {
   display: block;
  }
 
 
  .menu-toggle {
   display: block;
   padding: 10px;
   cursor: pointer;
 }
 
 .menu-items {
   display: none;
   list-style: none;
   margin: 0;
   padding: 0;
 }
 
 .menu-items.show {
   display: block;
 }
 
 .menu-items li {
   margin: 10px 0;
 }
 
 .menu-items a {
   display: block;
   padding: 10px;
   color: #333;
   text-decoration: none;
 }
 
 .menu-items a:hover {
   background-color: #eee;
 }
 .Menu_arrows{
  font-size: 14px;
 }
 .Menu_mobilelist_subitem{
  background-color:#17171F;
 }
 

 
 } 
 @media screen and (min-device-width: 320px) and (max-device-width: 380px){
  .getConBtn{
    padding:10px;
  }
  .Menu_arrows{
    font-size: 12px;
   }
 }
