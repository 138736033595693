/* Popup.css */

.success-message{
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000000;
    border: 1px solid #595959;
    /* padding: 20px; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 999999;
    transition: ease-in .09s;
    text-align: center;
  }
  .success-message>p{
    font-size: 30px;
    line-height: 40px;
    padding: 20px;
  }
.modal {
    position: fixed;
    overflow: hidden;
 overflow-y: scroll;
    top: 90;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999;
  }
  .modal-mobile {
 display: flex;
 align-items: center;
 justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

  }
  
  .modal-main-mobile{
    border: 2px solid white !important;

    background-color: rgb(0, 0, 0);
    color: white !important;;
    width: 53%;
    max-width: 500px;
    min-width: 280px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .fa-x{
    float: right;
    cursor: pointer;
  }
  
  .display-none {
    display: none;
  }
  
  .modal-main {
    border: 2px solid white !important;
    position: fixed;
    background-color: rgb(0, 0, 0);
    color: white !important;;
    width: 53%;
    max-width: 500px;
    top: 50%;
    left: 50%;
    height: 70vh;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  }

  .modal-main button {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .modal-main button:hover {
    background-color: #e2e2e2;
  }
  
  form {
    margin-top: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  textarea {
    height: 100px;
  }
  .react-tel-input .country-list .country-name {
    color: black;
  }



  /* __________________________________________________________ */
/* MEDIA QUARIES MEDIA QUARIES  MEDIA QUARIES  MEDIA QUARIES  */
/* ---------------------------------------------------------- */
/* @media (min-width: 320px) and (max-width: 379px) {

  .mobile-number21 {
    width: 200px;
  }


} */

/* Popup.css */

/* Keep existing styles */

/* Add media queries for responsiveness */
@media only screen and (max-width: 768px) {
  .modal-main {
    width: 80%;
    max-width: 90%;
  }

  .modal-main input[type="text"],
  .modal-main input[type="email"],
  .modal-main textarea {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .modal-main {
    width: 90%;
    max-width: 100%;
    padding: 10px;
  }

  .modal-main input[type="text"],
  .modal-main input[type="email"],
  .modal-main textarea {
    width: 100%;
    margin-bottom: 5px;
  }

  .modal-main button {
    width: 100%;
    margin-top: 10px;
  }
}

