body {

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    /* font-family: 'Open Sans', Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    /* font-family: 'Open Sans', Arial, sans-serif; */
}
a{
  text-decoration: none;
}
.nav-item{
  list-style-type: none;
}
body{
  margin: 0px;
  left:0px
}
/* html {
  scroll-behavior: smooth;
} */

::-webkit-scrollbar {
  width: 0;
  /* This will hide the scrollbar in Chrome, Safari, and Opera */
}

/* Height */
::-webkit-scrollbar {
  height: 0;
  /* This will hide the scrollbar in Chrome, Safari, and Opera */
}

/* Firefox */
/* For Firefox, you can use the scrollbar-width property */
/* Note: Firefox requires specifying a non-auto value for scrollbar-width to take effect */
* {
  scrollbar-width: none; /* Firefox */
}

/* Common styles for studios */

:root{
  -bg-white:#ffffff;
  -bg-black:#000000;

}
p{
  margin: 0px;
}
li{
  list-style: none;
}
