.cloud-int-conainer {
    width: 90%;
    margin: 0 auto;
 
  }
   
  .cloud-integration-section {
    width: 100%;
    height: 25vw;
    background-color: #6441a0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cloud-integration-section > h1 {
    width: 60%;
    font-size: 3vw;
    font-weight: bolder;
    color: white;
    text-align: center;
  }
  .clo-inte-intro-con {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  .clo-inte-intro-con {
    font-size: 1.5vw;
    line-height: 1.8vw;
  }
   
  .cloud-title-infos {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .cloud-box-paragraphs {
    width: 100%;
    padding: 20px;
  }
  .cloud-box-paragraphs > h2 {
    font-size: 1.7vw;
    font-weight: bolder;
    line-height: 1.4;
    color: #6441a0;
  }
  .cloud-box-paragraphs > p {
    font-size: 1.5vw;
    line-height: 1.3;
  }
   
  .clo-int-imp-com {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .clo-int-imp-com > h1 {
    font-size: 2.5vw;
    font-weight: bolder;
    line-height: 1.4;
    color: #6441a0;
  }
  .clo-int-imp-com > p {
    font-size: 1.5vw;
    line-height: 1.3;
  }
   
  .integration-conclusion {
    font-size: 1.5vw;
    line-height: 1.3;
  }
   
  .featured-content-two-full {
    width: 100%;
    /* background-color: #E7E7E8; */
    height: 22vw;
    display: flex;
 
    gap:3vw;
  }
  .cloud-info-details-section{
    margin-top: 10vw;
    margin-bottom: 10vw;
    display: flex;
    flex-direction: column;
    gap:15vw
  }
   
  .featured-content-two-full .imagebox {
    flex: 1;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .featured-content-two-full .featured-content-box {
    width: 48%;
    display: flex;
    align-items: center;

  }
   
  .featured-content-two-full .featured-content-box .featured-content-inner-title {

    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
  }
   
  .featured-content-two-full .featured-content-box h2 {
    font-size:2vw;
    font-weight: 900;
  margin: 0px;
  margin-bottom: 1vw;
    color: #6441a0;
  }
   
  .featured-content-two-full .featured-content-box p {
    font-size: 1.3vw;
    line-height: 1.52;
  }
   
   
  .featured-content-two-full.left-section11 .imagebox {
    width: 48%;
    order: 2;
    border-radius: 505px 0 0 505px;
    /* margin-left: 6rem; */
  }
   
  /* .featured-content-two-full.left-section11 .featured-content-box .featured-content-inner-title {
    margin-left: 154px;
  } */
   
  .featured-content-two-full.right .imagebox {
    border-radius: 0 505px 505px 0;
    margin-right: 6rem;
  }
   
  .integration-item-sec {
    width: 27vw;
    height: 9.5vw;
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: rgb(237, 237, 237);
    border-radius: 200px 1rem 1rem 200px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
   
    .integration-item-title{
      font-size: 1.4vw;
      display: flex;
      gap: 2rem;
      align-items: center;
      margin: 0;
    }
    .integration-pro-image {
      width: 8vw;
      aspect-ratio: 1;
      border-radius: 100vw;
      object-fit: cover;
    }
   
  }
  .integration-flex-group {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
   
  .integration-grid-flow{
    display: grid;
    gap: 1rem;
  }
  .integration-grid-flow > p {
    font-size: 1vw;
  }
   
  .integration-sta-section {
    display: flex;
    gap: 2rem;
    padding: 30px;
  }
   
 /* -------------------------------- */
