
.ShoppingHeadingStyle>h1{
  color: white;
  font-size: 45px;
}

/* -------------- Waves styling------------------- */

.wavesShopping {

  position: absolute; 

}





/* ------------------------------- */
.ShoppingWaveContainer {
background-color: #222121;
position: relative;
margin-top: 56px;
}
.wavesShopping {
width: 100%;
bottom: 0;
left: 0;
text-align: center;

}

.cls-1 {
opacity: 0.2;
}
.cls-2,
.cls-3 {
fill-rule: evenodd;
}
.cls-2 {
opacity: 0.7;
isolation: isolate;
fill: url(#linear-gradient);
}
.parallax > use {
animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
animation-delay: -2s;
animation-duration: 7s;
}
.parallax > use:nth-child(2) {
animation-delay: -3s;
animation-duration: 10s;
}
.parallax > use:nth-child(3) {
animation-delay: -4s;
animation-duration: 13s;
}
.parallax > use:nth-child(4) {
animation-delay: -5s;
animation-duration: 20s;
}
@keyframes move-forever {
0% {
  transform: translate3d(-90px, 0, 0);
}
100% {
  transform: translate3d(85px, 0, 0);
}
}


.st0 {
opacity: 0.201;
}

.st1 {
display: none;
opacity: 0.7;
fill: url(#Filled.Graph-4_1_);
enable-background: new;
}

.st2 {
fill: url(#gentle-wave_1_);
}


.ShoppingImagesSection{
display: flex;
flex-direction: column;
justify-content: center;
width: 100%;
height: 33vw;
padding: 0px 6vw;

align-items: center
}
.ShoppingHeadingStyle >h1{
  font-size: 3vw;
  text-align: center;
}
.Shopping-intro-content{
  line-height: 2.2vw;
    font-size: 1.6vw;
    font-weight: 600;
    color: #d0d0d0;
    text-align:center;
    width: 80%;

}

@media screen and (min-device-width: 320px) and (max-device-width: 480px){
  .ShoppingWaveContainer{
    margin: 0px;
    height: 65vw;
  }
  .ShoppingImagesSection{
    height: 65vw;
  }
  .ShoppingHeadingStyle >h1{
    font-size: 5vw;
  }
  .Shopping-intro-content>p{
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
  .Shopping-intro-content{
    width: 90%;
  }

}