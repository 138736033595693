 .TabContainer {
    background-color: #000000;
    max-width: 100%;
     border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  
  .Tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    text-align: center;
    color: #ffffff;
    background-color: #000000;
    position: sticky;
    top:70px;
    z-index: 1;
   padding: 0px;
   cursor: pointer;
    }
  
  .Tab {
    flex: 1 0 auto;
   padding: 10px;
    border-bottom: 2px solid transparent;
    font-weight: 500;
    font-size: 25px;
    color:#ffffff;
    list-style: none;
    text-decoration: none;
  }
 
  .Tab.is-selected {
    border-bottom: 2px solid #ffffff;
  
  }
  
  .TabPanel {
    color: #000;
   
  } 



  @media only screen and (max-device-width:768px){

    .Tabs{
     display: none;
    }
   
     }