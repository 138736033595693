.studios-section-home{
  padding: 2vw 0vw;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  /* height: 90vh; */
  background-color: #ffffff;
  display: flex;
gap: 1vw;
  align-items: center;
  justify-content: center;
}

.studios-main-head-title{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1vw 0vw;
}
.studios-main-head-title>h1{
  margin: 0px 0px 0px 0px;
  font-size: 3vw;
}


.studio-card{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 18vw;
  cursor: pointer;
  height: 27vw;
 border-radius: 1vw;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

}
.read-more-std{
  color: #fff;

  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
  font-style: italic;
}
.studio-card:hover{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.bg-orange{
  background-color:#F95933;
}
.studio-image{
  width: 80%;
  height: 60%;
}
.studio-image>img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.studio-info{
  margin-top: 0.5vw;
  height: 48%;
  padding: 1vw 1.5vw;
  color: #ffffff;
}
.studio-info>h3{
  font-size: 1.8vw;
  margin: 0px 0px 0.8vw 0px;
}
.studio-info>p{
  line-height: 1.2;
  font-size: 1.1vw;
  margin: 0px;
}
.studio-info>a{
  color: #fff;
}
.bg-purple{
  background-color: #6441A0;
}
.bg-green{
  background-color:#7DC85C ;
}
.bg-blue{
  background-color:#00AECF;
}
.bg-black{
  background-color: #000000;
}

.line-cards{
  margin-left: 0px ;
  width: 60%;
  border: 0.9px solid rgb(255, 255, 255);
  margin-bottom: 0.8vw;
}

@media (min-width: 320px) and (max-width: 480px) {
  .studios-main-head-title>h1{
    font-size: 7vw;
  }
}