*{
  font-family: mr-eaves-xl-sans, sans-serif;
/* font-weight: 100; */
font-style: normal;
box-sizing: border-box;

}
.app-top-padding{
  padding-top: 70px;
}

/* .section1,.section2,.section3,.section4{
  width: 100%;
  height: 100vh;
} */



 li.nav-item::marker{
  display: none;
 }

 .contact-form{
  background-color: antiquewhite;
  width: 300px;
  height: 300px;
  z-index: 999999;
 }
.bg-popup-home{
  width: 100%;
  height: 100%;
  background-color: white;
}

 .Desktop_view{
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99;
}

.Mobile-view{
  display: none;
}
@media only screen and (max-device-width: 768px){

  .Desktop_view{
      display: none;
  }
  .Mobile-view{
      display: block;
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 2;
      
  } 

}

/* __________________________________________________________ */
/* MEDIA QUARIES MEDIA QUARIES  MEDIA QUARIES  MEDIA QUARIES  */
/* ---------------------------------------------------------- */
@media (min-width: 380px) and (max-width: 479px) {
  .app-top-pading{
    padding-top: 0px;
  }

}
@media (min-width: 320px) and (max-width:620px) {
  .app-top-pading{
    padding-top: 0px;
  }

}