
  

  .line-h{
    margin-bottom: 1.2vw;
    margin-top: 1vw;
    width: 10vw;
    /* height: 0.1vw; */
    background-color: #000000;
    float: left;
  }


  .sub-description-studio>p{
    font-size: 1.4vw;
    font-weight: 400;
    color: #ffffff;
  }
  .sub-description-studio{
    margin-top: 1vw;
  }
  .photos-box.photo:nth-child(4) {
    /* Custom styles for the DC image box */
    border: 2px solid rgb(209, 200, 200);
      /* background-color: yellow; */
    }
 

  @media (min-width: 1380px) and (max-width: 1920px) {

    .swiper {
      height: 68vh;
    }
  }




  .spacer {
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 88vh;
    /* background: #efefef; */
  }
  
  .spacer > div {
    box-sizing: border-box;
    overflow: hidden;
    /* color: #fff; */
    font-weight: 900;
    font-size: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
  }
  
  .headline {
    width: 100%;
  }
  
  .text > p {
    font-size: 1.5vw;
    line-height: 2vw;
    font-weight: 600;
    color: #ffffff;
  }
  
  .gallery {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    overflow-x: hidden;
  }
  
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 65%;
  }
  
  .detailsWrapper {
    margin: auto;
    width: 80%;
    margin-top: 5vw;
  }
  
  .details {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .right {
    width: 35%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .photos {
    width: 40vw;
    height: 40vw;
    background: transparent;
    position: relative;
    margin-top: 14vw;
  }

  
  .photos-box {
    overflow: hidden;
    position: absolute;
    width: 70%;
    height: 70%;
    top: 0%;
    left: 10%;
    border-radius: 2vw;
    /* border: 0.1vw solid white; */
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
 
  
  .photo {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .photo img {
    width: 80%;
  }
  
  .head-std-hm {
    font-size: 3vw;
 margin: 0px;
 font-weight: 800;

  }
  
  .col1 {
    background: #f95933;
  }
  
  .col2 {
    background: #6441a0;
  }
  
  .col3 {
    background: #7dc85c;
  }
  
  .col4 {
    background: #00aecf;
  }
  
  .col5 {
    background: #000000;
    /* border: 1px solid white; */
  }
  .photos-box.photo:nth-child(5) {
    /* Custom styles for the DC image box */
    border: 2px solid red;
      /* background-color: yellow; */
    }
  
  
  
  .gallery {
    position: relative;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2); /* Adjust the color and opacity as needed */
    z-index: 1; /* Ensure the overlay is above the background but below other content */
  }
  
  .left, .right {
    position: relative;
    z-index: 2; /* Ensure these are above the overlay */
  }

  /* Know more buttons */

  .std-knwm-btn {
    cursor: pointer;
    margin-top: 3vw;
    width: 10vw;
    /* Variables */
    --button_radius: 0.55em;
    /* --button_outline_color:rgb(0, 0, 0); */
    font-size: 1vw;
    font-weight: bold;
    border: none;
    border-radius: var(--button_radius);
    /* background:  rgba(0, 0, 0,0.4) */
  }
  
  .button_top-std-knwm-btn {
    display: block;
    box-sizing: border-box;
    /* border: 2px solid rgba(0, 0, 0,) ; */
    border-radius: var(--button_radius);
    padding: 0.75em 1.5em;
    background: white;
    color: rgb(249, 89, 51);
    /* transform: translateY(-0.2em); */
    transition: transform 0.1s ease;
  }
  
  .std-knwm-btn:hover .button_top-std-knwm-btn {
    /* Pull the button upwards when hovered */
    transform: translateY(-0.33em);
  }
  
  .std-knwm-btn:active .button_top-std-knwm-btn {
    /* Push the button downwards when pressed */
    transform: translateY(0);
  }
  


  