
 .error404{
    height: 100vh;
    background: #000000;
    color:#fff;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: 'Titillium Web', sans-serif;
 }
 .box404>h2 {
    font-weight: 700;
    font-size: 25vh;
    margin: 0;
    line-height: 1;
    animation: light 4s steps(1) infinite;
 }
.box404>p{
    font-size: 5vh;
}
 @keyframes light {
    33% {
       text-shadow: 0 0 20px #e900fe;
    }
    66% {
       text-shadow: 0 0 20px #6de33c;
    }
 }

 .box404>a > button {
    width: 10em;
    position: relative;
    height: 3.5em;
    border: 3px ridge #149CEA;
    outline: none;
    background-color: transparent;
    color: white;
    transition: 1s;
    border-radius: 0.3em;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .box404>a>button::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 3%;
    width: 95%;
    height: 40%;
    background-color: #000000;
    transition: 0.5s;
    transform-origin: center;
  }
  
  .box404>a>button::before {
    content: "";
    transform-origin: center;
    position: absolute;
    top: 80%;
    left: 3%;
    width: 95%;
    height: 40%;
    background-color: #000000;
    transition: 0.5s;
  }
  
  .box404>a>button:hover::before, .box404>a>button:hover::after {
    transform: scale(0)
  }
  
  .box404> a> button:hover {
    box-shadow: inset 0px 0px 25px #1479EA;
  }