.articles-cnt-main{
    padding: 2vw 0vw;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    overflow-x:hidden ;
}
.article-title>h2{
    font-size: 3vw;
margin: 0px 0px 1vw 0px;

}
.article-btns{
    display: flex;
    align-items: center;
 justify-content: center;
 gap:3vw
  

}
.art-card1>h3{
    font-size: 1.5vw;
    color:#00aecf;
    margin: 0px 0px 1vw 0px;
}
.art-btn-item p {
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    color: #666;
  }
  
  .art-btn-item p.small {
    font-size: 14px;
  }
  
  .go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 2.2vw;
    height: 2.2vw;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color:#00aecf;
    border-radius: 0 4px 0 32px;
  }
  
  .go-arrow {
    margin-top: -0.1vw;
    margin-right: -0.1vw;
    color: white;
    font-family: courier, sans;
  }
  
  .art-card1 {
    display: block;
    position: relative;
    max-width: 16vw;
    background-color: #f2f8f9;
    border-radius: 0.5vw;
    padding: 2.5vw 1.6vw;
    margin: 1vw;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
  }
  
  .art-card1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -1vw;
    right: -1vw;
    background: #00aecf;
    height: 2.5vw;
    width: 2.5vw;
    border-radius: 2.6vw;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }
  
  .art-card1:hover:before {
    transform: scale(50);
  }
  
  .art-card1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .art-card1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
  }
  
  /* .art-card2 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 16vw;
    background-color: #f2f8f9;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #f2f8f9;
  } */
  
  .read-more-art{
   
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 480px){

    .art-card1{
      max-width: 45vw;
    }
    .art-btn-item p{
        font-size: 3.5vw;
        line-height: 3.5vw;
    }
    .article-btns{
      width: 100vw;
      display: flex;
      flex-wrap: wrap;
    }
    .art-card1>h3{
      font-size: 4vw;
    }
    .article-title>h2{
      font-size: 8vw;
    }
    .go-corner{
      width: 5vw;
      height: 5vw;
    }
 

  }