/* 
  section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    position: relative;
    overflow: hidden;
  }
  
  .section1 {

    background-image: url("../../assets/BgIntro.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
  }
  
  .content-1 {
   
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-1>p{
    color: #3e3e3e;
  }
  .section2 {
    background-color: black;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
  }
  
  .content-2 {
    transform: scale(0.1);
    opacity: 0;
    position: absolute;
    color: white !important;
  }
  
  .section3 {
    background-color: #f0f0f0;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
  }
  
  .content-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }
  
  .photo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
  }
  
  .photo img {
    max-height: 100%;
    max-width: 100%;
  } */


  /* zoom out */
/* General styles */
section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  position: relative;
  overflow: hidden;
}

.section1 {
  background-image: url("../../assets/BgIntro.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
}

.content-14 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-14 > p {
  color: #3e3e3e;
}

.section2 {
  background-color: black;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
}

.content-24 {
  transform: scale(0.1);
  opacity: 0;
  position: absolute;
  color: white !important;
}

.section3 {
  background-color: #f0f0f0;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
}

.content-34 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.photo img {
  max-height: 100%;
  max-width: 100%;
}

/* Mobile responsiveness */
 